import {
  SideBarModalOpen,
  SideBarModalClose,
  SideBarModalNavigate,
  SideBarModalPageData,
  SubTaskId,
} from "../constant";

const initialData = {
  isSideBarOpen: false,
  title: "",
  pageName: "",
  pageNumber: 1,
  projectId: "",
  firstPageData: {},
  TaskId: "",
  ownerId: "",
};
export const sideBarModalReducer = (data = initialData, action) => {
  switch (action.type) {
    case SideBarModalOpen:
      return {
        ...data,
        isSideBarOpen: true,
        title: action.payload.title,
        pageName: action.payload.pageName,
        pageNumber: action.payload.pageNumber,
        projectId: action.payload.projectId,
      };
    case SideBarModalClose:
      return { ...data, isSideBarOpen: false, TaskId: ""  ,ownerId:""};
    case SideBarModalNavigate:
      return {
        ...data,
        pageNumber: action.payload.pageNumber,
      };
    case SideBarModalPageData:
      return {
        ...data,
        firstPageData: action.payload,
      };
    case SubTaskId:
      return {
        ...data,
        TaskId: action.payload.taskId,
        ownerId:action.payload.ownerId,
      };
    default:
      return data;
  }
};
