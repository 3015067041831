import { logOut } from "../constant";

export const loginAction = (userPool, session) => {
  return {
    type: "loginPayload",
    payload: {
      username: "",
      session: session,
    },
  };
};

export const logOutAction = () => {
  return {
    type: logOut,
  };
};
