import { Suspense, lazy, useEffect, useMemo } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import Login from "./components/login/login";
import Layout from "./components/layout/layout";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
// import Dashboard from "./components/dashboard/dashboard";
// import Projects from "./components/projects/projects";
// import UserManagement from "./components/userManagement/Usermanagement";
import { useSelector } from "react-redux";
import "./App.css";
import PublicLayout from "./components/Auth/PublicLayout";
import TaskDetails from "./components/projects/TaskDetails/taskDetails";
import Logout from "./logout";
import Auth from "./auth";
const Dashboard = lazy(() => import("./components/dashboard/dashboard"));
const Projects = lazy(() => import("./components/projects/projects"));
const UserManagement = lazy(() =>
  import("./components/userManagement/Usermanagement")
);

function App() {
  const { loading } = useSelector((state) => state.loading);

  return (
    <>
      {loading && (
        <div>
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 111,
            }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
      <Router>
        <Routes>
          <Route
            index
            path="/"
            element={
              <PublicLayout>
                <Auth />
              </PublicLayout>
            }
          />
          <Route
            element={
              <Layout>
                <Outlet />
              </Layout>
            }
          >
            <Route path="/dashboard/" element={<Dashboard />} />
            <Route path="/projects/" element={<Projects />} />
            <Route path="/usermanagement/" element={<UserManagement />} />
            <Route path="/taskDetails/:taskId" element={<TaskDetails />} />
            <Route path="/logout" element={<Logout />} />
          </Route>
          <Route path="*" element={<Navigate to="/" replace />}></Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
