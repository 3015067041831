import React, { useEffect } from "react";
import axios from "axios";

function logout() {
  const responses = async () => {
    console.log("123");
    window.location =
      "https://azur.auth.us-east-1.amazoncognito.com/logout?client_id=qsroh39n7j11hft2uglnn1q5p&logout_uri=https%3A%2F%2Fgenaivisuals.com%2F";
    // const response = await axios.get(
    //   "https://azur.auth.us-east-1.amazoncognito.com/logout?client_id=qsroh39n7j11hft2uglnn1q5p&logout_uri=https%3A%2F%2Fgenaivisuals.com%2Flogout%2F"
    // );
    console.log("response", response);
  };

  useEffect(() => {
    responses();
  }, []);

  return <div>logout</div>;
}

export default logout;
