import {
  SideBarModalClose,
  SideBarModalOpen,
  SideBarModalNavigate,
  SideBarModalPageData,
  SubTaskId,
} from "../constant";

export const isSideBarModalOpen = (payload) => {
  return {
    type: SideBarModalOpen,
    payload: payload,
  };
};

export const subTaskid = (payload) => {
  return {
    type: SubTaskId,
    payload: payload,
  };
};

export const isSideBarModalClose = () => {
  return {
    type: SideBarModalClose,
  };
};

export const clickToNavigate = (payload) => {
  return {
    type: SideBarModalNavigate,
    payload: payload,
  };
};

export const ModalPageData = (payload) => {
  return {
    type: SideBarModalPageData,
    payload: payload,
  };
};
