import {
  serviceRunningStart,
  serviceFailure,
  serviceSuccess,
} from "../constant";

const intialState = {
  loading: false,
  error: "",
};

export const loadingReducer = (data = intialState, action) => {
  switch (action.type) {
    case serviceRunningStart:
      return { ...data, loading: true, error: "" };
    case serviceSuccess:
      return { ...data, loading: false, error: "" };
    case serviceFailure:
      return { error: action.payload, loading: false };
    default:
      return data;
  }
};
