export const loginPayload = "loginPayload";
export const sideBarOpen = "sideBarOpen";
export const sideBarClose = "sideBarClose";
export const currentUserDetails = "currentUserDetails";
export const logOut = "logOut";
export const fetchedUsers = "FETCHED_USERS";
export const fetchedRoles = "FETCHED_ROLES";
export const serviceRunningStart = "SERVICE_RUNNNING_START";
export const serviceFailure = "SERVICE_FAILURE";
export const serviceSuccess = "SERVICE_SUCCESS";
export const fetchedCurrentUserDetails = "FETCHED_CURRENT_USER_DETAILS";
export const fetchProjectList = "FETCH_PROJECT_LIST";
export const fetchProjectDetails = "FETCH_PROJECT_DETAILS";
export const sidebarTabClick = "SIDEBAR_TAB_CLICK";
export const UsersFetchingStart = "UserList_FETCH_START";
export const ProjectListFetchingStart = "PROJECTLIST_FECTHING_LOADING";
export const ProjectDetailsFetchingStart = "PROJECT_DETAILS_FECHING_LOADING";
export const FetchScriptListStart = "FETCH_SCRIPT_LIST_START";
export const FetchedScriptListSucess = "FETCHED_SCRIPT_LIST_SUCESS";
export const FetchedTaskListSucess = "FETCHED_TASK_LIST_SUCESS";
export const FetchBudgetListStart = "FETCH_BUDGET_LIST_START";
export const FetchedBudgetListSucess = "FETCHED_BUDGET_LIST_SUCESS";
export const FetchScheduleListStart = "FETCH_SCHEDULE_LIST_START";
export const FetchedScheduleListSucess = "FETCHED_SCHEDULE_LIST_SUCESS";
export const SideBarModalOpen = "SIDE_BAR_OPEN";
export const SideBarModalClose = "SIDE_BAR_CLOSE";
export const SideBarModalNavigate = "SIDE_BAR_MODAL_NAVIGATE";
export const SideBarModalPageData = "SIDE_BAR_MODAL_PAGE_DATA";
export const getScriptNoteList = "GET_SCRIPT_NOTE_LIST";
export const getSubTaskDetails = "GET_SUB_TASK_DETAILS";
export const getSubTaskDetailsList = "GET_SUB_TASK_DETAILS_LIST";
export const getSubTaskDetailsNotes = "GET_SUB_TASK_DETAILS_NOTES";
export const SubTaskId = "SubTaskId";
export const getSubTaskNoteList = 'getSubTaskNoteList';
export const setSubTaskAddedTime = 'setSubTaskAddedTime';
