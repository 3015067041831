import { loginPayload, currentUserDetails, logOut } from "../constant";
const intialState = { session: { username: "", session: "" } };

export const payloads = (data = intialState, action) => {
  switch (action.type) {
    case loginPayload:
      return {
        ...data,
        session: {
          username: action.payload.username,
          session: action.payload.session,
        },
      };
    case logOut:
      return intialState;
    default:
      return data;
  }
};
