import Breadcrumb from "./breadcrumb";
import TaskHeader from "./taskHeader";
import SubTask from "./subTask";
import "./taskDetails.css";
import NotesPage from "../../common/notes/notes";
import { Divider, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  fetchSubTaskDetails,
  fetchSubTaskDetailsList,
} from "../../../shared/redux/services/task";
import { useParams } from "react-router-dom";
import { getScriptNoteList } from "../../../shared/redux/services/notesService";

const TaskDetails = () => {
  const params = useParams();
  const { subTaskDetails } = useSelector((state) => state.task);
  const { currentUser } = useSelector((state) => state.userManagement);
  const dispath = useDispatch();
  useEffect(() => {
    fetchDataOnFirstRender();
  }, []);

  const fetchDataOnFirstRender = () => {
    if (currentUser.persona === "General Level Creative") {
      dispath(
        getScriptNoteList({
          scriptId: params.taskId,
          tabName: "subTask",
        })
      );
      dispath(
        fetchSubTaskDetails({
          subTaskId: params.taskId,
          persona: "generalLevel",
        })
      );
    } else {
      dispath(
        getScriptNoteList({
          scriptId: params.taskId,
          tabName: "task",
        })
      );
      dispath(
        fetchSubTaskDetails({
          subTaskId: params.taskId,
          persona: "scheduler",
        })
      );
    }

    if (currentUser.persona === "High Level Creative")
      dispath(
        fetchSubTaskDetailsList({
          taskId: params.taskId,
        })
      );
  };
  return (
    <div className="main-wrapper relative">
      <Grid container spacing={1} style={{ margin: "0px" }}>
        {/* Breadcrumb */}
        <Breadcrumb details={subTaskDetails} />

        {/* Task Header */}
        <TaskHeader
          page="detail"
          fetchDataOnFirstRender={fetchDataOnFirstRender}
        />
        {/* Content  */}

        <Grid container className="section-row">
          <Grid className="section-left" lg={8} xs={12} sm={12} md={8}>
            <SubTask
              single={true}
              details={subTaskDetails}
              fetchDataOnFirstRender={fetchDataOnFirstRender}
            />
            {currentUser.persona === "High Level Cretive" ? (
              <>
                <Divider style={{ margin: "40px 0px" }} />
                <SubTask
                  single={false}
                  fetchDataOnFirstRender={fetchDataOnFirstRender}
                />
              </>
            ) : null}
          </Grid>
          <Grid className="section-right" lg={4} xs={12} sm={12} md={4}>
            <NotesPage
              selectedId={subTaskDetails}
              tabName={
                currentUser.persona === "General Level Creative"
                  ? "subTask"
                  : "task"
              }
            ></NotesPage>
          </Grid>
        </Grid>
      </Grid>
      {/* Main content */}
      {/* <TaskWithSubTask /> */}
    </div>
  );
};

export default TaskDetails;
