import { sideBarOpen, sideBarClose, sidebarTabClick } from "../constant";

const intialState = {
  collaspe: false,
  activeTab: "Dashboard",
};

export const sideBarReducer = (data = intialState, action) => {
  switch (action.type) {
    case sideBarOpen:
      return { ...data, collaspe: true };
    case sideBarClose:
      return { ...data, collaspe: false };
    case sidebarTabClick:
      return { ...data, activeTab: action.payload };
    default:
      return data;
  }
};
