import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
  memo,
} from "react";
import "./notes.css";
import Grid from "@mui/material/Unstable_Grid2";
import { styled } from "@mui/material/styles";
import producerIcon from "../../../assets/images/producerIcon.png";
import DateIcon from "../../../assets/images/dateIcon.svg";
import TimeIcon from "../../../assets/images/timeIcon.svg";
import { useSelector, useDispatch } from "react-redux";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import Button from "@mui/material/Button";
import {
  addScriptNote,
  getScriptNoteList,
  getScriptNoteListByUser,
} from "../../../shared/redux/services/notesService";
import CloseIcon from '@mui/icons-material/Close';

const NotesPage = ({ selectedId = [], tabName, closeNotes, ...rest }) => {
  const dispatch = useDispatch();
  const valueRef = useRef("");
  const [isButtonVisible, setButtonVisible] = useState(false);
  const [textareaValue, setTextareaValue] = useState();
  const { noteList, subTasknoteList, projectDetails  } = useSelector((state) => state.projects);
  const { currentUser, users } = useSelector((state) => state.userManagement);
  const { subTaskDetails } = useSelector((state) => state.task);
  const [tableData, setTableData] = useState([]);

  const handleTextareaChange = () => {
    setTextareaValue(valueRef?.current?.value);
    if (valueRef?.current?.value === "") {
      setButtonVisible(false);
      return;
    }
    setButtonVisible(true);
  };

  const getUserNote = (userId) => {
    dispatch(
      getScriptNoteListByUser({
        scriptId: rest?.subTaskId || selectedId?._id,
        userId: userId,
        tabName: tabName,
      })
    );
  };

  const addNote = async () => {
    if (textareaValue) {
      const obj = {
        userId: currentUser._id,
        note: textareaValue,
      };
      setTextareaValue("");
      const data = dispatch(
        addScriptNote({
          userData: obj,
          scriptId: rest?.subTaskId || selectedId?._id,
          tabName: tabName,
        })
      );
      setButtonVisible(false);
    }
  };

  const generateRandomColor = (seed) => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[(seed + i) % 16];
    }
    return color;
  };

  const handleAll = () => {
    dispatch(getScriptNoteList({ scriptId: rest?.subTaskId || selectedId?._id, tabName: tabName }));
  };

  useEffect(() => {
    let tableDatas = [];
    if (tabName === "subTask") {
      const newSubTaskDetails = closeNotes ? rest.details : subTaskDetails;
      tableDatas = [{ ...newSubTaskDetails?.owner }];
      if(subTaskDetails?.createdBy && newSubTaskDetails?.owner?._id !== subTaskDetails?.createdBy?._id) {
        tableDatas = [...tableDatas, { ...subTaskDetails?.createdBy}];
      }
      if (newSubTaskDetails.hasOwnProperty("member")) {
        tableDatas = [...tableDatas, { ...newSubTaskDetails?.member }];
      }
    } else if (tabName === "task") {
      tableDatas = [{ ...subTaskDetails?.owner }];
      if(subTaskDetails?.createdBy && subTaskDetails?.owner?._id !== subTaskDetails?.createdBy?._id) {
        tableDatas = [...tableDatas, { ...subTaskDetails?.createdBy}];
      }
      if (subTaskDetails?.members) {
        tableDatas = [...tableDatas, ...subTaskDetails?.members];
      }
      if(subTaskDetails.isAssembly) {
        tableDatas = [...tableDatas, { ...projectDetails?.owner }];
      }
    } else {
      if(projectDetails?.owner) {
        tableDatas = [projectDetails?.owner];
      }
      if(projectDetails?.members && projectDetails?.members.length > 0) {
        tableDatas = [...tableDatas, ...projectDetails?.members];
      }
    }
    setTableData(tableDatas);
  }, [projectDetails, subTaskDetails, rest]);

  const sortedTableItems =
    tableData &&
    tableData.sort((a, b) =>
      currentUser._id === a._id ? -1 : currentUser._id === b._id ? 1 : 0
    );

  const notes = useMemo(() => {
    if(tabName === 'subTask') {
      return subTasknoteList;
    } else {
      return noteList;
    }
  }, [noteList, subTasknoteList]);

  return (
    <div className="Notes_wrapper">
      <Grid container className="noteContent">
        <Grid>
          <h3 className="notes_text"> 
            {tabName?.toLowerCase()?.includes('task') ? 'Task Notes' : 'Notes'} 
            { 
              closeNotes && <CloseIcon onClick={closeNotes} />
            }
          </h3>
        </Grid>
        <div className="noteData">
          <Grid container spacing={1} className="noteContainer">
            <Grid xs={2} md={2} className="col1Grid">
              <div className="imagesWrap">
                <div className="AllImageText" onClick={() => handleAll()}>
                  All
                </div>

                {sortedTableItems &&
                  sortedTableItems.map((item, index) => (
                    <div
                      className="images"
                      onClick={() => getUserNote(item._id)}
                      key={index}
                    >
                      <div
                        className="initial-name"
                        title={item?.firstName}
                        style={{
                          backgroundColor: generateRandomColor(index),
                        }}
                      >
                        {item?.firstName?.charAt(0) || "A"}
                      </div>
                    </div>
                  ))}
              </div>
            </Grid>
            <Grid xs={10} md={10}>
              <div className="wrapTextAdd">
                <BaseTextareaAutosize
                  maxRows={3}
                  minRows={3}
                  ref={valueRef}
                  onChange={handleTextareaChange}
                  aria-label="maximum height"
                  placeholder="Write Your notes here..."
                  className="textArea"
                  value={textareaValue}
                />
                {isButtonVisible && (
                  <div className="noteSubmit">
                    <Button
                      size="medium"
                      className="bottomBtnCancel"
                      variant="outlined"
                      onClick={() => setTextareaValue("")}
                    >
                      Cancel
                    </Button>
                    <ColorButton
                      className="btnAddNote"
                      size="small"
                      onClick={addNote}
                    >
                      Add
                    </ColorButton>
                  </div>
                )}
              </div>
              <div className="notesItems">
                {notes &&
                  notes.map((item) => {
                    let data = new Date(item.createdDate);
                    let month = monthNames[data.getMonth()];
                    let date = data.getDate();
                    return (
                      <div key={item._id}>
                        <div className="wrapBox">
                          <div className="nameImageWrap">
                            <div className="imageDiv">
                              <div
                                className="initial-name"
                                title={item?.firstName}
                                style={{
                                  backgroundColor: generateRandomColor(
                                    parseInt(item?._id, 16)
                                  ),
                                }}
                              >
                                {item?.firstName?.charAt(0) || "A"}
                              </div>
                            </div>
                            <div className="alignDropValueSelected">
                              <div className="nameRole">
                                <div className="nameAlignSelected">
                                  {item.firstName}
                                </div>
                                <div className="designamtionSelected no-overflow">
                                  {item.title}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="boxNotes">{item.note}</div>
                          <div className="timeDate">
                            <div>
                              <img
                                src={DateIcon}
                                alt=""
                                className="timeDateImage"
                              />
                              {`${month} ${date}`}
                            </div>
                            <div>
                              <img
                                src={TimeIcon}
                                alt=""
                                className="timeDateImage"
                              />
                              {formatAMPM(data)}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </div>
  );
};

function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  var strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export default memo(NotesPage);

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#173035"),
  backgroundColor: "#173035",
  width: "112px",
  "&:hover": {
    backgroundColor: "#173035",
  },
}));
