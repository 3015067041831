import { Button, Checkbox, TextField } from "@mui/material";
import TextareaAutosize from "@mui/material/TextareaAutosize";

import subTaskStep from "../../assets/images/subTaskStep.svg";
import warning from "../../assets/images/dragDropFile/warning.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clickToNavigate } from "../../shared/redux/action/sideBarModalAction";
import CustomButton from "../common/customButton/customButton";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { getScriptsList } from "../../shared/redux/reducer/projectReducer";
import "./subTaskAddForm.css";

const SubTaskAddForm = ({ getSecondPageData, pageName, firstPageData }) => {
  const scriptList = useSelector(getScriptsList);
  const { currentUser } = useSelector((state) => state.userManagement);
  const [selectedDate, setSelectedDate] = useState(
    firstPageData?.dueDate ? firstPageData?.dueDate : ""
  );
  const [subtaskDetails, setSubtaskDetails] = useState({
    title: firstPageData?.title ? firstPageData?.title : "",
    description: firstPageData?.description ? firstPageData?.description : "",
    tool: ["LeoStream"],
    createdBy: currentUser._id,
    priority: firstPageData?.priority ? firstPageData?.priority : "",
  });

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleChange = (e) => {
    const { value, name, checked } = e.target;
    if (name === "isAssembly") {
      setSubtaskDetails({ ...subtaskDetails, [name]: checked });
    } else {
      setSubtaskDetails({ ...subtaskDetails, [name]: value });
    }
  };
  const dispatch = useDispatch();

  function extractFilename(input) {
    const parts = input.split("/");
    const filenameWithExtension = parts[parts.length - 1];
    const filenameWithoutExtension = filenameWithExtension.split(".")[0];
    return filenameWithoutExtension;
  }
  return (
    <>
      <div className="formWrapper">
        <img src={subTaskStep} />
        <h6>{pageName == "SubTask" ? "Sub Task Details" : "Task Details"}</h6>
        <div className="forminnerWrapper">
          <div className="textControl">
            <label>
              {pageName == "SubTask" ? "Sub Task Title" : "Task Title"}
              <span style={{ color: "red" }}>*</span>
            </label>
            <TextField
              id="outlined-error"
              placeholder={`Enter ${pageName} title`}
              fullWidth={true}
              size="small"
              className={`textField`}
              name="title"
              onChange={handleChange}
              value={subtaskDetails.title}
            />
          </div>

          <div className="textControl">
            <label>
              Select Priority<span style={{ color: "red" }}>*</span>
            </label>
            <Select
              onChange={(e) => handleChange(e)}
              size="small"
              displayEmpty
              name="priority"
              value={subtaskDetails.priority || ""}
              renderValue={(value) => {
                if (!value) {
                  return (
                    <em style={{ fontSize: "16px", color: " #bcbcbc" }}>
                      Select Priority
                    </em>
                  );
                }
                return value;
              }}
            >
              <MenuItem value="" disabled>
                <em>Select Priority</em>
              </MenuItem>
              <MenuItem value="High">High</MenuItem>
              <MenuItem value="Medium">Medium</MenuItem>
              <MenuItem value="Low">Low</MenuItem>
            </Select>
          </div>
          {currentUser?.persona == "Scheduler" && pageName == "Task" && (
            <div className="mark-as-assembly">
              <div className="labelItem">Mark as Assembled</div>
              <div className="mark-as-assembly-wrapper">
                <img src={warning} width="20px" height="20px" /> Tasks MUST be
                “Marked as Assembled” below in order to be available for the
                Finance & Control to view the final files.
              </div>
              <div className="CheckboxWrapper">
                <Checkbox onChange={handleChange} name="isAssembly" />
                <span className="Checkboxlabel">Mark as Assembled</span>
              </div>
            </div>
          )}

          <div className="textControl">
            <label>
              Task Description<span style={{ color: "red" }}>*</span>
            </label>

            <textarea
              id="outlined-error"
              placeholder={`Enter ${pageName} description here...`}
              // placeholder="Enter task description here..."
              fullWidth={true}
              size="small"
              className={`textField`}
              name="description"
              onChange={handleChange}
              value={subtaskDetails.description}
            />
          </div>
          <div className="textControl">
            <label>Script Version</label>
            <Select
              onChange={(e) => handleChange(e)}
              size="small"
              displayEmpty
              name="scriptId"
              value={subtaskDetails.scriptId || ""}
              renderValue={(value) => {
                if (!value) {
                  return (
                    <em style={{ fontSize: "16px", color: " #bcbcbc" }}>
                      Select Script Version
                    </em>
                  );
                }
                // Render the selected value if any
                const selectedScript = scriptList.find(
                  (item) => item._id === value
                );
                return selectedScript
                  ? extractFilename(selectedScript.s3ObjectKey)
                  : "";
              }}
            >
              <MenuItem value="" disabled>
                <em>Select Script Version</em>
              </MenuItem>
              {scriptList.map((item) => (
                <MenuItem value={item._id} key={item._id}>
                  {extractFilename(item.s3ObjectKey)}
                </MenuItem>
              ))}
            </Select>
          </div>
          <div className="textControl datePickerWrapper">
            <label>
              Due Date<span style={{ color: "red" }}>*</span>
            </label>
            <DatePicker
              id="datePicker"
              className={`textField`}
              selected={selectedDate}
              onChange={handleDateChange}
              dateFormat="yyyy-MM-dd"
            />
          </div>
          <div className="textControl">
            <label>Assign Tools</label>
            <div className="toot-wrapper">
              <div className="toot-item active">
                <div className="toot-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                  >
                    <path
                      d="M5.33464 28C4.6013 28 3.97352 27.7389 3.4513 27.2167C2.92908 26.6944 2.66797 26.0667 2.66797 25.3333V6.66667C2.66797 5.93333 2.92908 5.30556 3.4513 4.78333C3.97352 4.26111 4.6013 4 5.33464 4H26.668C27.4013 4 28.0291 4.26111 28.5513 4.78333C29.0735 5.30556 29.3346 5.93333 29.3346 6.66667V25.3333C29.3346 26.0667 29.0735 26.6944 28.5513 27.2167C28.0291 27.7389 27.4013 28 26.668 28H5.33464ZM6.66797 22.6667H13.3346V20H6.66797V22.6667ZM19.4013 20L26.0013 13.4L24.1013 11.5L19.4013 16.2333L17.5013 14.3333L15.6346 16.2333L19.4013 20ZM6.66797 17.3333H13.3346V14.6667H6.66797V17.3333ZM6.66797 12H13.3346V9.33333H6.66797V12Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <div className="tool-text">
                  LeoStream<span>Virtual Studios</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="submitButton">
        <CustomButton
          label="Previous"
          type="outlineButton"
          onclick={() => dispatch(clickToNavigate({ pageNumber: 1 }))}
        />

        <CustomButton
          label="Next"
          type={`${
            subtaskDetails.title == "" ||
            subtaskDetails.title == "" ||
            subtaskDetails.description == "" ||
            subtaskDetails.priority == "" ||
            selectedDate == "" ||
            selectedDate == null
              ? "disabledButton"
              : "mainButton"
          }`}
          onclick={() => {
            dispatch(clickToNavigate({ pageNumber: 3 }));
            getSecondPageData({ ...subtaskDetails, dueDate: selectedDate });
          }}
        />
      </div>
    </>
  );
};

export default SubTaskAddForm;
