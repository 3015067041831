import { useState, useEffect } from "react";
import UploadFile from "../common/uploadFile/uploadFile";
import DisplayModal from "../createProject/displayModal";
import Modal from "../common/Modal/modal";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  clickToNavigate,
  isSideBarModalClose,
} from "../../shared/redux/action/sideBarModalAction";
import { getUploadScriptURL } from "../../shared/redux/services/userService";
import SubTaskAddForm from "./SubTaskAddFrom";
import {
  AddSubTaskAction,
  AddTaskAction,
  FetchScriptListAction,
} from "../../shared/redux/services/projectService";
import CustomButton from "../common/customButton/customButton";

const ModalContent = () => {
  const {
    isSideBarOpen,
    pageName,
    title,
    pageNumber,
    projectId,
    TaskId,
    ownerId,
  } = useSelector((state) => state.sideBarModal);
  const { currentUser } = useSelector((state) => state.userManagement);
  const [personaKey, setPersonaKey] = useState("");
  
  useEffect(() => {
    if (pageName == "Task" || pageName == "SubTask")
      dispatch(FetchScriptListAction({ _id: projectId }));
  }, [projectId]);

  useEffect(() => {
    if (Object.keys(currentUser).length > 0) {
      switch (currentUser?.persona) {
        case "Finance & Control":
          setPersonaKey("finance");
          break;
        case "Scheduler":
          setPersonaKey("scheduler");
          break;
        case "High Level Creative":
          setPersonaKey("highLevel");
          break;
        default:
          setPersonaKey("generalLevel");
      }
    }
  }, [currentUser?._id]);
  const dispatch = useDispatch();
  const handleClose = () => dispatch(isSideBarModalClose);
  const [firstPageData, setFirstPageData] = useState();
  const [secondPageData, setSecondPageData] = useState();
  const [fileData, setFile] = useState();
  const [taskId, setTaskId] = useState("");
  const [uploadingFiles, setUploadingFiles] = useState([])

  const onFileChange = async (files) => {
    if (files.length === 0) {
      setFile();
    } else {
      const fileDetails = files;
      setFile(fileDetails);
    }
  };

  const importFile = async () => {
    try {
      const getUploadURLs = await dispatch(
        getUploadScriptURL({
          files: fileData,
          projectId: projectId,
          firstPageData: firstPageData,
          pageName: pageName,
          userId: currentUser?._id,
          persona: personaKey,
          taskId: taskId,
          mainTaskId: TaskId,
        })
      );
      if (getUploadURLs === "success") {
        handleMainClose(false);
      }
      return;
    } catch (e) {}
  };

  const handleMainClose = () => {
    dispatch(isSideBarModalClose());
  };

  const getFirstPageData = (item) => {
    setFirstPageData({ ...item, ...firstPageData });
  };

  const getSecondPageData = async (item) => {
    setFirstPageData({ ...firstPageData, ...item });
    setSecondPageData(item);
    let response;
    if (taskId == "") {
      if (pageName == "Task") {
        response = await dispatch(
          AddTaskAction({
            id: projectId,
            payload: { ...firstPageData, ...item },
          })
        );
      } else {
        response = await dispatch(
          AddSubTaskAction({
            id: TaskId,
            payload: { ...firstPageData, ...item, owner: ownerId },
          })
        );
      }
      setTaskId(response.data.data._id);
    }
  };

  return (
    <div>
      <Modal show={isSideBarOpen} handleClose={handleMainClose} header={title}>
        
        {pageNumber === 1 ? (
          <DisplayModal
            pageName={pageName}
            title={title}
            getFirstPageData={getFirstPageData}
            firstPageData={firstPageData}
            personaKey={personaKey}
          />
        ) : null}

        {pageName !== "Project" && pageNumber > 1 ? (
          <>
            {(pageName == "Task" || pageName == "SubTask") &&
            pageNumber === 2 ? (
              <SubTaskAddForm
                getSecondPageData={getSecondPageData}
                pageName={pageName}
                firstPageData={firstPageData}
              />
            ) : (
              <div>
                <div className="formWrapper">
                  <UploadFile
                    onFileChange={(files) => onFileChange(files)}
                    pageName={pageName}
                    setUploadingFiles={setUploadingFiles}
                    uploadingFiles={uploadingFiles}
                  />
                </div>
                <div className="submitButton">
                  <CustomButton
                    label="Previous"
                    type="mainButton"
                    onclick={() => {
                      dispatch(clickToNavigate({ pageNumber: pageNumber - 1 }));

                    }}
                  />

                  <CustomButton
                    label="Add"
                    type="outlineButton"
                    onclick={() => importFile()}
                  />
                </div>
              </div>
            )}
          </>
        ) : null}
      </Modal>
    </div>
  );
};

export default ModalContent;
