import React, { useEffect, useState } from 'react';
import CustomButton from '../../common/customButton/customButton';
import { useDispatch, useSelector } from 'react-redux';
import { getSubTaskNotes } from '../../../shared/redux/services/notesService';
import { Box, Drawer } from '@mui/material';
import NotesPage from "../../common/notes/notes";
import {
    getScriptNoteList,
  } from "../../../shared/redux/services/notesService";
import { getTaskDetailByType } from '../../../shared/redux/services/task';

const SubTaskNotes = ({ subTaskId }) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const { subTaskDetails } = useSelector((state) => state.task);
    const [details, setDetails] = useState({});

    const viewAllNotes = async () => {
        window.scrollTo(0, 0);
        try {
            const response = await getTaskDetailByType('subTask', subTaskId);
            setDetails(response.data.data || {});
            dispatch(getScriptNoteList({ scriptId: subTaskId, tabName: 'subTask' }));
            setOpen(true);
        } catch {
            setOpen(false);
        }
    }

    useEffect(() => {
        if(open) {
            document.getElementById("xconnect-body").style = "overflow: hidden;";
        } else {
            document.getElementById("xconnect-body").style = "overflow: unset;";
        }
      }, [open]);

    return (
        <div style={{ margin: "30px 0px" }}>
            <CustomButton
                label="View all notes"
                type="outlineButton"
                onclick={viewAllNotes}
            />
            { open && <div
                style={{
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: "100%",
                    height: "100vh",
                    color: "white",
                    zIndex: 9,
                }}
            >
                <Box sx={{ display: "flex", height: "100%", justifyContent: 'end' }}>
                    <div style={{ display: "flex" }}>
                        <Drawer
                            anchor="right"
                            variant="permanent"
                            open={open}
                            classes={{ paper: `w-drawer relative ${!open && "d-none"}` }}
                        >
                            <NotesPage
                                selectedId={subTaskDetails}
                                tabName="subTask"
                                closeNotes={() => setOpen(false)}
                                subTaskId={subTaskId}
                                details={details}
                            ></NotesPage>
                        </Drawer>
                    </div>
                </Box>
            </div> }
        </div>
    );
};

export default SubTaskNotes;