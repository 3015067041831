import { useEffect, useMemo, useState } from "react";
import warning from "../../assets/images/dragDropFile/warning.png";
import progressBar from "../../assets/images/uploadProgressIndicatorFirst.svg";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import producerIcon from "../../assets/images/producerIcon.png";
import closeIcon from "../../assets/images/closeIcon.svg";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import { batch, useDispatch, useSelector } from "react-redux";
import {
  addProject,
  addProjectMember,
} from "../../shared/redux/services/projectService";
import ConformationModal from "../common/conformationModal/conformationModal";
import Button from "@mui/material/Button";
import {
  ModalPageData,
  clickToNavigate,
  isSideBarModalClose,
} from "../../shared/redux/action/sideBarModalAction";
import "./displayModal.css";
import {
  serviceFailureAction,
  serviceRunningStartAction,
} from "../../shared/redux/action/loadingAction";
import CustomAlert from "../common/Toaster/toaster";

const DisplayModal = ({
  pageName,
  title,
  getFirstPageData,
  firstPageData,
  personaKey,
}) => {
  const dispatch = useDispatch();
  const [member, setMember] = useState([]);
  const [projectName, setProjectName] = useState("");
  const { users, currentUser, roles } = useSelector(
    (state) => state.userManagement
  );
  const { projectList, scriptList, budgetList, scheduleList } = useSelector(
    (state) => state.projects
  );
  const { projectId } = useSelector((state) => state.sideBarModal);
  const { activeTab } = useSelector((state) => state.sideBar);
  const [showConformationModal, setShowConformationModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState();
  const [isValidForm, setIsValidForm] = useState(false);

  const handleClose = () => setShowConformationModal(false);
  useEffect(() => {
    if (firstPageData) {
      const updatedUsers = users
        .map((user) => {
          if (firstPageData.owner === user._id) {
            return { ...user, desgination: "owner" };
          } else if(firstPageData?.member && user?._id === firstPageData?.member) {
            return { ...user, desgination: "member" }
          } else if (firstPageData?.members?.includes(user._id)) {
            return { ...user, desgination: "member" };
          }
          return null;
        })
        .filter(Boolean);
      setMember(updatedUsers);
    } else {
      const projectDetails = projectList.find((item) => {
        return item._id === projectId;
      });
      if (title === "Add Member") {
        const member = projectDetails?.members?.map((item) => ({
          ...item,
          desgination: "member",
        }));
        setMember([
          { ...projectDetails?.owner, desgination: "owner" },
          ...member,
        ]);
        setProjectName(projectDetails.title);
      } else if (
        ["Upload Script", "Upload Budget", "Upload Schedule"].includes(title)
      ) {
        const config = {
          "Upload Script": {
            list:
              activeTab === "Dashboard"
                ? projectDetails?.scriptsDetail
                : scriptList,
            checkItem: "scripts",
          },
          "Upload Budget": {
            list:
              activeTab === "Dashboard"
                ? projectDetails?.budgetsDetail
                : budgetList,
            checkItem: "budgets",
          },
          "Upload Schedule": {
            list:
              activeTab === "Dashboard"
                ? projectDetails?.scheduleList
                : scheduleList,
            checkItem: "schedules",
          },
        };
        const baseObj = config[title];
        if (baseObj?.list[baseObj.checkItem]?.length > 0) {
          const member = baseObj?.list?.members?.map((item) => ({
            ...item,
            desgination: "member",
          }));
          const finalMembers = [
            { ...baseObj?.list?.owner, desgination: "owner" },
            ...member,
          ];
          setMember(finalMembers);
        }
      } else if (title === "Upload Schedule") {
        if (projectDetails?.scheduleList?.schedules?.length > 0) {
          const member = projectDetails?.scheduleList?.members?.map((item) => ({
            ...item,
            desgination: "member",
          }));
          setMember([
            { ...projectDetails?.scheduleList?.owner, desgination: "owner" },
            ...member,
          ]);
        }
      }
    }
  }, []);

  const generateRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < users.length; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const handleChange = (event, selectedItem) => {
    const {
      target: { value },
    } = event;
    if (pageName !== "SubTask") {
      const owner = member.find((item) => item.desgination == "owner");
      const cloneMember = [...member];
      const index = member.findIndex(
        (option) => option._id === selectedItem._id
      );
      if (
        pageName === "Project" &&
        selectedItem.role !=
          roles.find((item) => item.name == "Finance & Control")._id &&
        value == "owner"
      ) {
        dispatch(
          serviceFailureAction(
            "Only Finance & Control user can be a project Owner"
          )
        );
      } else if (owner && owner._id !== selectedItem._id) {
        setShowConformationModal(true);
        setActiveIndex(index);
      } else {
        cloneMember[index] = {
          ...cloneMember[index],
          ["desgination"]: value,
        };
      }

      setMember(cloneMember);
    }
  };

  const getDropdownValue = (e, v, reason) => {
    if (v == null) {
      return;
    }
    let arr = [];
    if (reason === "selectOption" && pageName != "SubTask") {
      const selected = v.filter(function (cv) {
        return !member.find(function (ev) {
          return ev._id == cv._id;
        });
      });
      const data = selected.map((item) => {
        return { ...item, desgination: "member" };
      });
      const groupByList = member.reduce(
        (acc, option) => {
          if (option.desgination === "owner") {
            acc.owner = [option];
          } else {
            acc.member = [...acc.member, option];
          }
          return acc;
        },
        { member: [], owner: [] }
      );

      if (member?.length === 0) {
        setMember(data);
      } else {
        setMember([...groupByList?.owner, ...data, ...groupByList?.member]);
      }
    } else if (pageName == "SubTask") {
      const selected = v.filter(function (cv) {
        return !member.find(function (ev) {
          return ev._id == cv._id;
        });
      });
      const data = selected.map((item) => {
        return { ...item, desgination: "member" };
      });
      setMember(data[0] ? [data[0]] : []);
    } else {
      for (let i = 0; i < v.length; i++) {
        arr.push(v[i]._id);
      }
      const selected = member.filter((cv) => arr.includes(cv._id));
      setMember(selected);
    }
  };

  const removeSelectedRow = (e) => {
    const dropDownSelectedRemoved = member.filter((item) => item._id !== e._id);
    setMember([...dropDownSelectedRemoved]);
  };

  useEffect(() => {
    setIsValidForm(isValid());
  }, [member, projectName]);

  const isValid = () => {
    const owner = member.findIndex((item) => item.desgination == "owner");
    if (pageName == "SubTask" && member.length > 0) {
      return true;
    }
    if (owner < 0 || (pageName === "Project" && projectName === "")) {
      return false;
    }

    return true;
  };

  const onSubmit = () => {
    if (isValidForm) {
      const payload = {
        title: projectName,
        owner: member.find((item) => {
          if (item.desgination == "owner") {
            return item._id;
          }
        })?._id,
        members: member
          .filter((item) => {
            if (item.desgination == "member") {
              return item._id;
            }
          })
          .map((item) => item._id),
      };
      if (title === "Add Member") {
        dispatch(
          addProjectMember({
            payload: payload,
            id: projectId,
            userId: currentUser?._id,
            persona: personaKey,
          })
        );
      } else {
        dispatch(
          addProject({
            payload: payload,
            userId: currentUser?._id,
            persona: personaKey,
          })
        );
      }
    }
  };

  const onNext = () => {
    if (isValidForm) {
      const payload = {
        projectId: projectId,
        owner: member.find((item) => {
          if (item.desgination == "owner") {
            return item._id;
          }
        })?._id,
      };
      if (pageName == "SubTask") {
        payload["member"] = member[0]._id;
      } else {
        payload["members"] = member
          .filter((item) => {
            if (item.desgination == "member") {
              return item._id;
            }
          })
          .map((item) => item._id);
      }
      delete payload.title;
      getFirstPageData(payload);
      dispatch(clickToNavigate({ pageNumber: 2 }));
      dispatch(ModalPageData(payload));
    }
  };

  const handleMainClose = () => {
    dispatch(isSideBarModalClose());
  };

  const onConfirm = () => {
    const cloneMember = [...member];
    const owner = member.findIndex((item) => item.desgination == "owner");
    cloneMember[activeIndex] = {
      ...cloneMember[activeIndex],
      ["desgination"]: "owner",
    };
    cloneMember[owner] = {
      ...cloneMember[owner],
      ["desgination"]: "member",
    };

    setMember(cloneMember);
    handleClose();
  };

  const filterOptions = (options, { inputValue }) => {
    let filterData = [];
    const data = options.filter((option) => {
      return !member.some(
        (selectedOption) => selectedOption._id === option._id
      );
    });
    if (inputValue) {
      filterData = data.filter((item) => {
        return Object.values(item).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(inputValue.toLowerCase())
        );
      });
    } else filterData = [...data];
    return filterData;
  };

  const newMembers = useMemo(() => {
    const arr = member.reduce(
      (acc, option) => {
        if (option.desgination === "owner") {
          acc.owner = [option];
        } else {
          acc.member = [...acc.member, option];
        }
        return acc;
      },
      { member: [], owner: [] }
    );
    return [...arr.owner, ...arr.member];
  }, [member, setMember]);

  function findMatchingRoles(description, array1, array2) {
    const roleId = array1.find((item) => item.name === description)?._id;
    if (!roleId) {
      return [];
    }
    const matchingRoles = array2.filter((item) => item.role === roleId);
    return matchingRoles;
  }
  return (
    <div className="wrapModelContent">
      <div className="wrapModel">
        <ConformationModal
          show={showConformationModal}
          handleClose={handleClose}
          title={`Change ${pageName} Ownership`}
          description={`Are you sure you want to transfer ownership of ${
            member[member.findIndex((item) => item.desgination == "owner")]
              ?.firstName
          } to ${member[activeIndex]?.firstName}?`}
          onConfirm={onConfirm}
        />
        {pageName === "Project" ? (
          <>
            <label className="fieldLable">Project Name</label>
            <TextField
              id="outlined-read-only-input"
              value={projectName}
              className="fieldText"
              placeholder="Enter project name"
              size="small"
              onChange={(e) => setProjectName(e.target.value)}
            />
          </>
        ) : (
          <img src={progressBar} />
        )}
        <p className="heading">
          {pageName == "SubTask"
            ? "Assign Task"
            : `Assign ${pageName} Owner & members`}{" "}
        </p>
        {pageName !== "SubTask" && (
          <div className="modelContainer">
            <div>
              <img src={warning} />{" "}
            </div>
            <div className="secondColumn">
              <p className="title">
                {`Adding a ${pageName} owner is mandatory. The ${pageName} Owner holds
              the final authority in decision-making.`}
              </p>
            </div>
          </div>
        )}
        <div className="selectUsersDiv">
          <Autocomplete
            multiple
            value={newMembers}
            filterSelectedOptions
            id="country-select-demo"
            options={
              pageName == "Task"
                ? // ? projectList
                  //     .find((item) => {
                  //       return item._id === projectId;
                  //     })
                  //     .members.filter(
                  //       (item) => item.role == "65a8c4b7436fd3db98fc2399"
                  //     )
                  findMatchingRoles(
                    "High Level Creative",
                    roles,
                    projectList.find((item) => {
                      return item._id === projectId;
                    }).members
                  )
                : pageName == "SubTask"
                ? // projectList
                  //     .find((item) => {
                  //       return item._id === projectId;
                  //     })
                  //     .members.filter(
                  //       (item) => item.role == "65a8c4b7436fd3db98fc239a"
                  //     )
                  findMatchingRoles(
                    "General Level Creative",
                    roles,
                    projectList.find((item) => {
                      return item._id === projectId;
                    }).members
                  )
                : pageName == "Budget" ||
                  pageName == "Script" ||
                  pageName == "Schedule"
                ? [
                    ...projectList.find((item) => {
                      return item._id === projectId;
                    })?.members,
                    projectList.find((item) => {
                      return item._id === projectId;
                    })?.owner,
                  ]
                : users
            }
            // options={users}
            autoHighlight
            size="small"
            filterOptions={filterOptions}
            className="selectUsers"
            getOptionLabel={(option) => `${option.firstName}`}
            onChange={(e, v, reason) => getDropdownValue(e, v, reason)}
            renderOption={(props, option) => {
              return (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                  key={option?._id}
                >
                  <div className="alignDropValue">
                    <div>
                      {!producerIcon ? (
                        <img src={producerIcon} alt="User" />
                      ) : (
                        <div
                          className="initial-nameDropList"
                          style={{
                            backgroundColor: generateRandomColor(
                              option?.firstName.charAt(0)
                            ),
                          }}
                        >
                          {option?.firstName.charAt(0)}
                        </div>
                      )}
                    </div>
                    <p className="nameAlign">
                      {" "}
                      {`${option?.firstName} ${option?.lastName}`}{" "}
                      <p className="designamtion"> {option?.title} </p>
                    </p>
                  </div>
                </Box>
              );
            }}
            renderInput={(params) => (
              <TextField
                size="small"
                {...params}
                placeholder="Search for users by name, email or title etc"
                inputProps={{
                  ...params.inputProps,
                }}
              />
            )}
          />
        </div>
        <div className="memberSelectedheight">
          {newMembers.map((item, index) => (
            <div key={index}>
              <div className="selectedVlueDropDown">
                <div className="nameImageWrap">
                  <div className="imageDiv">
                    {!producerIcon ? (
                      <img src={producerIcon} alt="User" />
                    ) : (
                      <div
                        className="initial-nameDropList"
                        style={{
                          backgroundColor: generateRandomColor(
                            item?.firstName?.charAt(0)
                          ),
                        }}
                      >
                        {item?.firstName?.charAt(0)}
                      </div>
                    )}
                  </div>

                  <div className="alignDropValueSelected">
                    <div className="nameRole">
                      <div className="nameAlignSelected">{item?.firstName}</div>
                      <div className="designamtionSelected">
                        {" "}
                        {item?.title}{" "}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    item.desgination === "owner"
                      ? "memberSelectedOwner"
                      : "memberSelected"
                  }
                >
                  {pageName == "SubTask" ? (
                    <span
                      style={{
                        width: "65px",
                        height: "17px",
                        justifyContent: "center",
                      }}
                    >
                      {item.desgination}
                    </span>
                  ) : (
                    <FormControl>
                      <Select
                        defaultValue={"member"}
                        id="demo-simple-select"
                        value={item.desgination}
                        onChange={(e) => handleChange(e, item)}
                      >
                        <MenuItem value={"member"}>Member</MenuItem>
                        <MenuItem value={"owner"}>{pageName} Owner</MenuItem>
                      </Select>
                    </FormControl>
                  )}
                </div>
                <div className="itemCrossBtn">
                  <img
                    loading="lazy"
                    width="24"
                    height="24"
                    className="imageCloseIcon"
                    srcSet={closeIcon}
                    onClick={() => removeSelectedRow(item)}
                    alt=""
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="submitButton">
        <Button
          size="medium"
          className="bottomBtnCancel"
          variant="outlined"
          onClick={handleMainClose}
        >
          Cancel
        </Button>
        {pageName === "Project" ? (
          <Button
            size="small"
            className={
              isValidForm === true
                ? "btn btn-create"
                : "btn btn-create-disabled"
            }
            onClick={onSubmit}
            disabled={isValidForm === false ? true : false}
          >
            Create
          </Button>
        ) : (
          <ColorButton
            size="small"
            className={
              isValidForm === true
                ? "btn btn-create"
                : "btn btn-create-disabled"
            }
            onClick={onNext}
            disabled={isValidForm == false ? true : false}
          >
            Next
          </ColorButton>
        )}
      </div>
    </div>
  );
};

export default DisplayModal;

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#173035"),
  backgroundColor: "#173035",
  width: "112px",
  "&:hover": {
    backgroundColor: "#173035",
  },
}));
