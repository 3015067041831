import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import "./conformationModal.css";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function ConformationModal({
  show,
  handleClose,
  title,
  description,
  onConfirm,
}) {
  return (
    <div className="modalWrap">
      <React.Fragment>
        {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open dialog
      </Button> */}
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={show}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            {title}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Typography gutterBottom>{description}</Typography>
          </DialogContent>
          <DialogActions>
            <Button className="btnCancel" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="outlined"
              className="confirmButton"
              onClick={onConfirm}
            >
              Confirm
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </React.Fragment>
    </div>
  );
}

// import Modal from "react-bootstrap/Modal";
// import Button from "@mui/material/Button";
// import * as React from 'react';
// import { styled } from '@mui/material/styles';
// import Dialog from '@mui/material/Dialog';
// import DialogTitle from '@mui/material/DialogTitle';
// import DialogContent from '@mui/material/DialogContent';
// import DialogActions from '@mui/material/DialogActions';
// import IconButton from '@mui/material/IconButton';
// import CloseIcon from '@mui/icons-material/Close';
// import Typography from '@mui/material/Typography';

// const ConformationModal = ({
//   show,
//   handleClose,
//   title,
//   description,
//   onConfirm,
// }) => {

//   return (
//     <Modal show={show} onHide={handleClose} style={{ zIndex: "9999" }}>
//       <Modal.Header closeButton>
//         <Modal.Title>{title}</Modal.Title>
//       </Modal.Header>
//       <Modal.Body>{description}</Modal.Body>
//       <Modal.Footer>
//         <Button className="btnCancel" onClick={handleClose}>
//           Close
//         </Button>
//         <Button
//           variant="outlined"
//           className="confirmButton"
//           onClick={onConfirm}
//         >
//           Confirm
//         </Button>
//       </Modal.Footer>
//     </Modal>
//   );
// };

// export default ConformationModal;
