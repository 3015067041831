import { CognitoUserPool } from "amazon-cognito-identity-js";

let poolData = {
  UserPoolId: process.env.REACT_APP_USER_POOL_DEV,
  ClientId: process.env.REACT_APP_CLIENT_ID_DEV,
};

if (process.env.NODE_ENV === "production") {
  poolData = {
    UserPoolId: process.env.REACT_APP_USER_POOL_PROD,
    ClientId: process.env.REACT_APP_CLIENT_ID_PROD,
  };
}

export const userPool = new CognitoUserPool(poolData);
