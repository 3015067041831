import { memo } from "react";
import "./customButton.css";

const CustomButton = ({
  label = "hello",
  type = "mainButton",
  onclick = () => {},
  frontIcon = false,
  endIcon = false,
  enableNote = false,
}) => {
  return (
    <button
      className={type}
      onClick={onclick}
      disabled={type === "disabledButton" || enableNote}
      style={{
        backgroundColor:
          type === "disabledButton" ? "#E7E8E8" : "",
        cursor:
          type === "disabledButton" || enableNote ? "not-allowed" : "pointer",
      }}
    >
      {frontIcon && <img src={frontIcon} />}
      {label}
      {endIcon && <img src={endIcon} />}
    </button>
  );
};

export default memo(CustomButton);
