import {
  serviceFailure,
  serviceRunningStart,
  serviceSuccess,
} from "../constant";

export const serviceRunningStartAction = () => {
  return {
    type: serviceRunningStart,
  };
};

export const serviceFailureAction = (data) => {
  return {
    type: serviceFailure,
    payload: data,
  };
};

export const serviceSuccessAction = () => {
  return {
    type: serviceSuccess,
  };
};
