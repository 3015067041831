import {
  subTaskDetails,
  subTaskDetailsList,
  subTaskDetailsNotes,
} from "../action/task";
import axiosInstance from "./interseptor";

export const getTaskDetailByType = async (path, id) => {
  return axiosInstance.get(`/${path}/${id}`)
}

export const fetchSubTaskDetails = (payload) => async (dispatch) => {
  const path = payload.persona === "generalLevel" ? "subTask" : "task";
  try {
    const response = await getTaskDetailByType(path, payload.subTaskId);
    dispatch(subTaskDetails(response.data.data));
    return response.data.data;
  } catch (e) {
    console.log("error", e);
  }
};

export const fetchSubTaskDetailsList = (payload) => async (dispatch) => {
  try {
    const response = await axiosInstance.get(
      `/task/${payload.taskId}/subTask?showNote=${true}`
    );
    dispatch(subTaskDetailsList(response.data.data));
    return response.data.data;
  } catch (e) {
    console.log("error", e);
  }
};

export const fetchSubTaskDetailsNotes = (payload) => async (dispatch) => {
  try {
    const response = await axiosInstance.get(
      `/subTask/${payload.subTaskId}/note?userId=${payload.userId}`
    );
    dispatch(subTaskDetails(response.data.data));
    return response.data.data;
  } catch (e) {
    console.log("error", e);
  }
};

export const fetchSubTaskDetailsAllNotes = (payload) => async (dispatch) => {
  try {
    const response = await axiosInstance.get(
      `/subTask/${payload.subTaskId}/note`
    );
    dispatch(subTaskDetailsNotes(response.data.data));
    return response.data.data;
  } catch (e) {
    console.log("error", e);
  }
};
