import { getSubTaskDetails, getSubTaskDetailsList, getSubTaskDetailsNotes, setSubTaskAddedTime } from "../constant";

export const subTaskDetails = (data) => {
  return {
    type: getSubTaskDetails,
    payload: data,
  };
};

export const subTaskDetailsList = (data) => {
  return {
    type: getSubTaskDetailsList,
    payload: data,
  };
};

export const subTaskDetailsNotes = (data) => {
  return {
    type: getSubTaskDetailsNotes,
    payload: data,
  };
};

export const subTaskAdded = () => {
  return {
    type: setSubTaskAddedTime
  }
}
