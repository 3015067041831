import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { purple } from "@mui/material/colors";
import progressBar from "../../../assets/images/uploadProgressIndicatorSecond.svg";
import { ImageConfig } from "./imageConfig";
import uploadImg from "../../../assets/images/dragDropFile/cloud-upload-regular-240.png";
import deleteImg from "../../../assets/images/dragDropFile/deleteFile.png";
import { Divider } from "@mui/material";
import "./uploadFile.css";

const UploadFile = (props) => {
  const wrapperRef = useRef(null);
  const [fileList, setFileList] = useState(
    props?.uploadingFiles?.length > 0 ? props.uploadingFiles : []
  );
  const onDragEnter = () => wrapperRef.current.classList.add("dragover");
  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");
  const onDrop = () => wrapperRef.current.classList.remove("dragover");
  const onFileDrop = (e) => {
    let newFile = {};
    if (props.pageName === "user") {
      newFile = e.target.files;
      props?.onFileChange([...newFile]);
      setFileList([...newFile]);
    } else {
      newFile = e.target.files;
      if (newFile) {
        const updatedList = [...fileList, ...newFile];
        setFileList(updatedList);
        props?.setUploadingFiles(updatedList);
        props?.onFileChange(updatedList);
      }
    }
  };

  const fileRemove = (file) => {
    const updatedList = [...fileList];
    updatedList.splice(fileList.indexOf(file), 1);
    setFileList(updatedList);
    props.setUploadingFiles(updatedList);
    props.onFileChange(updatedList);
  };

  const fileInputRef = useRef();

  const handleChange = () => {
    // do something with event data
  };
  return (
    <>
      {props.pageName !== "user" ? <img src={progressBar} /> : null}
      <p className="uploadtitle">
        {props.pageName === "user"
          ? "Upload CSV File"
          : props.pageName == "Task" || props.pageName == "SubTask"
          ? "Add media"
          : `Upload ${props.pageName}`}
      </p>
      <div className="fileContainer">
        <div
          ref={wrapperRef}
          className="drop-file-input"
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
        >
          <div className="drop-file-input__label">
            <img src={uploadImg} alt="" />
            <p>Drag & Drop your files here</p>
          </div>
          {props.pageName === "user" ? (
            <input
              type="file"
              ref={wrapperRef}
              value=""
              onChange={(e) => onFileDrop(e)}
              name="file"
              multiple={false}
              accept="text/csv"
            />
          ) : (
            <input
              onChange={(e) => onFileDrop(e)}
              multiple
              ref={wrapperRef}
              type="file"
              name="file"
              value={[]}
              accept={
                props.pageName == "Task" || props.pageName == "SubTask"
                  ? ".pdf,.mp4"
                  : ".pdf"
              }
            />
          )}
        </div>
        <Divider>Or</Divider>
        <Button
          variant="contained"
          onClick={() => fileInputRef.current.click()}
          className="uploadButton"
        >
          {props.pageName === "user" ? "Upload CSV File" : "Upload PDF File"}
        </Button>
        <span style={{ textAlign: "center", marginTop: "16px" }}>
          Maximum file size is 100MB
        </span>
        {props.pageName === "user" ? (
          <input
            onChange={(e) => onFileDrop(e)}
            multiple={false}
            ref={fileInputRef}
            type="file"
            name="file"
            value=""
            hidden
            accept="text/csv"
          />
        ) : (
          <input
            onChange={(e) => onFileDrop(e)}
            multiple={true}
            ref={fileInputRef}
            type="file"
            name="file"
            value={[]}
            hidden
            accept={
              props.pageName == "Task" || props.pageName == "SubTask"
                ? ".pdf,.mp4"
                : ".pdf"
            }
          />
        )}
      </div>
      {fileList.length > 0 ? (
        <div className="drop-file-preview">
          <p className="drop-file-preview__title">Ready to upload</p>
          {fileList.map((item, index) => {
            let type = item.type;
            return (
              <div key={index} className="drop-file-preview__item">
                <img
                  src={
                    ImageConfig[type?.split("/")[1]] || ImageConfig["default"]
                  }
                  alt=""
                />
                <div className="drop-file-preview__item__info">
                  <p className="fileName">{item.name}</p>
                  <p className="size">
                    {(parseInt(item.size) / 1024 / 1023).toFixed(2)} MB
                  </p>
                </div>
                <span
                  className="drop-file-preview__item__del"
                  onClick={() => fileRemove(item)}
                >
                  <img src={deleteImg} />
                </span>
              </div>
            );
          })}
        </div>
      ) : null}
    </>
  );
};

UploadFile.propTypes = {
  onFileChange: PropTypes.func,
};

export default UploadFile;

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#173035"),
  backgroundColor: "#173035",
  "&:hover": {
    backgroundColor: "#173035",
  },
}));
