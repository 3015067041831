import { useNavigate } from "react-router-dom";
import { onSideBarTabClick } from "../../../shared/redux/action/sidebarActions";
import { useDispatch, useSelector } from "react-redux";

const Breadcrumb = ({ details }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.userManagement);
  const { collaspe, activeTab } = useSelector((state) => state.sideBar);
  const onhandleClick = () => {
    if (activeTab == "Dashboard") {
      dispatch(onSideBarTabClick("Dashboard"));
      navigate("/dashboard");
    } else {
      let activeTab;
      switch (currentUser?.persona) {
        case "Finance & Control":
          activeTab = 4;
          break;
        case "Scheduler":
          activeTab = 4;
          break;
        case "High Level Creative":
          activeTab = 0;
          break;
        default:
          activeTab = 0;
      }
      const state = { activeTab: activeTab };
      navigate("/projects", { state });
      dispatch(onSideBarTabClick("Project"));
    }
  };

  return (
    <div className="breadcrumb-wrapper" aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a onClick={onhandleClick}>Tasks</a>
        </li>
        <li>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M12.6 12L8 7.4L9.4 6L15.4 12L9.4 18L8 16.6L12.6 12Z"
              fill="#697172"
            />
          </svg>
        </li>
        <li class="breadcrumb-item" aria-current="page">
        <span class="restrictContent">{details?.title}</span></li>
      </ol>
    </div>
  );
};

export default Breadcrumb;
