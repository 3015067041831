import React, { useState, useEffect } from "react";
import "./App.css";
import { Amplify } from "aws-amplify";
import config from "./aws-exports";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { getCurrentUser } from "aws-amplify/auth";
import { signIn, signUp } from "aws-amplify/auth";
import axios from "axios";
import { useDispatch } from "react-redux";
import { loginAction } from "./shared/redux/action/action";
import { useNavigate } from "react-router-dom";

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: config.userPoolId,
      userPoolClientId: config.userPoolWebClientId,
    },
  },
});

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [jwtToken, setJwtToken] = useState("");
  const query = new URLSearchParams(window.location.search);
  const code = query.get("code");
  useEffect(() => {
    if (code) {
      getToken(code);
    }
  }, []);

  const setCookies = (cname, cvalue, exdays) => {
    console.log(cvalue, exdays);
    var now = new Date();
    var time = now.getTime();
    console.log("time", time);
    var expireTime = time + 1000 * exdays;
    now.setTime(expireTime);
    let expires = "expires=" + now.toUTCString();
    console.log("expires", expires);
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  };

  const getToken = async (code) => {
    const config = {
      headers: {
        Authorization:
          "Basic cXNyb2gzOW43ajExaGZ0MnVnbG5uMXE1cDoxMWRrYjlzbWloY2ZxZ2RtaW1hdTg5OGNxZ2E2aGwya2FnczByaWM5dXZncXRpOWwzb2pj",
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    const data = {
      grant_type: "authorization_code",
      code: code,
      client_id: "qsroh39n7j11hft2uglnn1q5p",
      client_secret: "11dkb9smihcfqgdmimau898cqga6hl2kags0ric9uvgqti9l3ojc",
      redirect_uri: "https://genaivisuals.com/",
    };
    try {
      const response = await axios.post(
        "https://azur.auth.us-east-1.amazoncognito.com/oauth2/token",
        data,
        config
      );
      console.log("response", response);
      dispatch(loginAction("", response.data));
      localStorage.setItem("accessToken", response.data.id_token);
      setCookies("id_token", response.data.id_token, response.data.expires_in);
      setCookies(
        "access_token",
        response.data.access_token,
        response.data.expires_in
      );
      setCookies(
        "refresh_token",
        response.data.refresh_token,
        response.data.expires_in
      );
      navigate("/dashboard");
      return response;
    } catch (e) {
      console.log("error", e);
    }
  };

  //   useEffect(() => {
  //     signIn(username, password);
  //   }, []);

  //   async function signIn({ username, password }) {
  //     try {
  //       const { isSignedIn, nextStep } = await signIn({ username, password });
  //     } catch (error) {
  //       console.log("error signing in", error);
  //     }
  //   }
  return (
    <div className="loginPageBtn">
      <a
        href="https://azur.auth.us-east-1.amazoncognito.com/oauth2/authorize?client_id=qsroh39n7j11hft2uglnn1q5p&response_type=code&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=https%3A%2F%2Fgenaivisuals.com%2F"
        className="singleBtn"
      >
        Corporate SignIn
      </a>
    </div>
  );
}

export default App;
