import {
  FetchBudgetListStart,
  FetchScheduleListStart,
  FetchScriptListStart,
  FetchedBudgetListSucess,
  FetchedScheduleListSucess,
  FetchedScriptListSucess,
  FetchedTaskListSucess,
  ProjectDetailsFetchingStart,
  ProjectListFetchingStart,
  fetchProjectDetails,
  fetchProjectList,
  getScriptNoteList,
  getSubTaskNoteList,
} from "../constant";

export const ProjectListFetchingStartAction = () => {
  return {
    type: ProjectListFetchingStart,
  };
};

export const FetchProjectList = (data = []) => {
  return {
    type: fetchProjectList,
    payload: data,
  };
};

export const ProjectDetailsFetchingStartAction = () => {
  return {
    type: ProjectDetailsFetchingStart,
  };
};

export const FetchProjectDetails = (data) => {
  return {
    type: fetchProjectDetails,
    payload: data,
  };
};

export const FetchedScriptList = (data) => {
  return {
    type: FetchedScriptListSucess,
    payload: data,
  };
};

export const FetchedTaskList = (data) => {
  return {
    type: FetchedTaskListSucess,
    payload: data,
  };
};

export const FetchScriptListStartAction = () => {
  return {
    type: FetchScriptListStart,
  };
};

export const FetchedBudgetList = (data) => {
  return {
    type: FetchedBudgetListSucess,
    payload: data,
  };
};

export const FetchScheduleListStartAction = () => {
  return {
    type: FetchScheduleListStart,
  };
};

export const FetchedScheduleList = (data) => {
  return {
    type: FetchedScheduleListSucess,
    payload: data,
  };
};

export const FetchBudgetListStartAction = () => {
  return {
    type: FetchBudgetListStart,
  };
};

export const getScriptNoteLists = (data) => {
  return {
    type: getScriptNoteList,
    payload: data,
  };
};

export const getSubTaskNoteLists = (data) => {
  return {
    type: getSubTaskNoteList,
    payload: data,
  };
};
