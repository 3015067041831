import axios from "axios";
import {
  fetchedUser,
  fetchedRole,
  fetchedCurrentUserAction,
  UsersFetchingStartAction,
} from "../action/usermanagementActions";
import axiosInstance from "./interseptor";
import { isSideBarModalClose } from "../action/sideBarModalAction";
import {
  FetchBudgetListAction,
  FetchPojectsList,
  FetchScheduleListAction,
  FetchScriptListAction,
  FetchSubTaskListAction,
  FetchTaskListAction,
} from "./projectService";

export const fetchUser = () => async (dispatch) => {
  try {
    dispatch(UsersFetchingStartAction());
    const response = await axiosInstance.get(`/user`);
    if (response?.status === 200) {
      dispatch(fetchedUser(response.data.data));
    }
  } catch (e) {
    console.log("error", e);
  }
};

export const fetchRole = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get(`/role`);
    dispatch(fetchedRole(response.data.data));
    return response;
  } catch (e) {
    console.log("error", e);
  }
};

export const addUser = (payload) => async (dispatch) => {
  try {
    const response = await axiosInstance.post(`/user`, payload);
    if (response?.status === 200) {
      dispatch(fetchUser());
    }
    return response;
  } catch (e) {
    console.log("error", e);
  }
};

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

export const userDetails = () => async (dispatch) => {
  try {
    const accessToken = getCookie("access_token");
    const userInfoResponse = await axios.get(
      `https://azur.auth.us-east-1.amazoncognito.com/oauth2/userInfo`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const email = userInfoResponse.data.email;
    const response = await axiosInstance.get(`/user/${email}`);
    const responses = await axiosInstance.get(`/role`);
    let roles = responses.data.data;
    let currentUser = response.data.data;
    let persona = "";
    if (roles.length > 0 && Object.keys(currentUser).length > 0) {
      persona = roles.filter((item) => currentUser.role === item._id)[0].name;
    }
    dispatch(fetchedCurrentUserAction({ ...currentUser, persona: persona }));

    return response;
  } catch (e) {
    console.log("error", e);
  }
};

export const userDetails1 = () => async (dispatch) => {
  try {
    const user = localStorage.getItem("Email");
    const response = await axiosInstance.get(`/user/${user}`);
    const responses = await axiosInstance.get(`/role`);
    let roles = responses.data.data;
    let currentUser = response.data.data;
    let persona = "";
    if (roles.length > 0 && Object.keys(currentUser).length > 0) {
      persona = roles.filter((item) => currentUser.role === item._id)[0].name;
    }
    dispatch(fetchedCurrentUserAction({ ...currentUser, persona: persona }));

    return response;
  } catch (e) {
    console.log("error", e);
  }
};

export const getUploadURL = (payload) => async (dispatch) => {
  let file = payload;
  let fileType = file.type.split("/")[1];
  try {
    const response = await axiosInstance.get(`/user/signedUrl/${fileType}`);
    const res = response.data.data;
    const { uploadUrl, key } = res;
    const result = await axios.put(uploadUrl, file, {
      headers: {
        "Content-Type": "*/*",
      },
    });
    if (result.status === 200) {
      dispatch(importUserCall(key));
      return "success";
    }
  } catch (e) {
    console.log("error", e);
  }
};

export const getUploadScriptURL = (payload) => async (dispatch) => {
  let file = payload.files;
  let page = payload.pageName.toLowerCase();
  const fileNames = file.map((item) => item.name);
  const fileSize = file.map(
    (item) => `${(parseInt(item.size) / 1024 / 1023).toFixed(2)} MB`
  );
  try {
    let response;
    if (payload.pageName == "Task") {
      response = await axiosInstance.get(
        `project/${payload.projectId}/${page}/${
          payload.taskId
        }/signedUrl?fileNames=${JSON.stringify(fileNames)}`
      );
    } else if (payload.pageName == "SubTask") {
      response = await axiosInstance.get(
        `project/${payload.projectId}/task/${payload.mainTaskId}/subTask/${
          payload.taskId
        }/signedUrl?fileNames=${JSON.stringify(fileNames)}`
      );
    } else {
      response = await axiosInstance.get(
        `project/${
          payload.projectId
        }/${page}/signedUrl?fileNames=${JSON.stringify(fileNames)}`
      );
    }

    const res = response.data.data;
    const uploadData = res.map((item, index) => {
      return { s3ObjectKey: item.key, size: fileSize[index] };
    });

    let data;
    if (payload.pageName == "Task" || payload.pageName == "SubTask") {
      data = {
        ...payload.firstPageData,
        [`media`]: [...uploadData],
      };
    } else {
      data = {
        ...payload.firstPageData,
        [`${page}s`]: [...uploadData],
      };
    }

    let success = [];
    const uploadResponse = await requestAll(res, file);

    success = uploadResponse.filter((item) => item.status === "rejected");

    if (success.length === 0) {
      dispatch(
        uploadScript({
          data: data,
          pageName: page,
          userId: payload.userId,
          projectId: payload.projectId,
          persona: payload.persona,
          taskId: payload.taskId,
        })
      );
    }
  } catch (e) {
    console.log("error", e);
  }
};

const requestAll = async (requestItems, file) => {
  return await Promise.allSettled(
    requestItems.map(async (url, index) => {
      try {
        return await axios.put(url.uploadUrl, file[index], {
          headers: {
            "Content-Type": "*/*",
          },
        });
      } catch (err) {
        console.log(err);
      }
    })
  );
};

export const importUserCall = (payload) => async (dispatch) => {
  try {
    const response = await axiosInstance.post(`${payload}`);
    console.log("respinse--->", response);
    if (response?.status == 200) {
      dispatch(fetchUser());
    }
  } catch (e) {
    console.log("error", e);
  }
};

export const uploadScript = (payload) => async (dispatch) => {
  try {
    let response;
    if (payload.pageName == "task") {
      response = await axiosInstance.put(
        `/${payload.pageName}/${payload.taskId}`,
        payload.data
      );
    } else if (payload.pageName == "subtask") {
      response = await axiosInstance.put(
        `/subTask/${payload.taskId}`,
        payload.data
      );
    } else {
      response = await axiosInstance.post(
        `/${payload.pageName}/`,
        payload.data
      );
    }

    if (response.data.message === "Success") {
      dispatch(isSideBarModalClose());
      dispatch(
        FetchPojectsList({ id: payload.userId, persona: payload.persona })
      );
      if (payload.pageName === "task" || payload.pageName === "subtask")
        dispatch(
          FetchTaskListAction({
            projectId: payload.projectId,
            userId: payload.userId,
          })
        );
      // if (payload.pageName === "subtask")
      //   dispatch(
      //     FetchSubTaskListAction({
      //       projectId: payload.projectId,
      //       userId: payload.userId,
      //     })
      //   );
      if (payload.pageName === "script")
        dispatch(FetchScriptListAction({ _id: payload.projectId }));
      if (payload.pageName === "budget")
        dispatch(FetchBudgetListAction({ _id: payload.projectId }));
      if (payload.pageName === "schedule")
        dispatch(FetchScheduleListAction({ _id: payload.projectId }));
    }
    dispatch();
  } catch (e) {
    console.log("error", e);
  }
};

export const downloadURL = (payload) => async (dispatch) => {
  try {
    const response = await axiosInstance.get("user/signedUrl", {
      responseType: "blob", // Set the response type to 'blob' for binary data
    });
    return response;
  } catch (e) {
    console.log("error", e);
  }
};
