import {
  UsersFetchingStart,
  fetchedCurrentUserDetails,
  fetchedRoles,
  fetchedUsers,
  logOut,
} from "../constant";

const intialState = {
  loadingUsers: false,
  users: [],
  roles: [],
  currentUser: {},
};

const usermanagementReducer = (state = intialState, action) => {
  switch (action.type) {
    case UsersFetchingStart:
      return { ...state, loadingUsers: true };
    case fetchedUsers:
      return { ...state, users: action.payload, loadingUsers: false };
    case fetchedRoles:
      return { ...state, roles: action.payload };
    case fetchedCurrentUserDetails:
      return { ...state, currentUser: action.payload };
    default:
      return state;
  }
};
export default usermanagementReducer;
