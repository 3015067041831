import { sideBarOpen, sideBarClose, sidebarTabClick } from "../constant";

export const sideBarOpenAction = () => {
  return {
    type: sideBarOpen,
  };
};

export const sideBarCloseAction = () => {
  return {
    type: sideBarClose,
  };
};

export const onSideBarTabClick = (payload) => {
  return {
    type: sidebarTabClick,
    payload,
  };
};
