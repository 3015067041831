import fireIcon from "../../../assets/images/fireIcon.svg";
import downwardIcon from "../../../assets/images/downwardIcon.svg";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select, MenuItem } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Autocomplete from "@mui/material/Autocomplete";
import { Grid, TextField, Tooltip } from "@mui/material";

import {
  FetchSubTaskListAction,
  FetchTaskListAction,
  TaskStatusList,
  UpdateSubTaskAction,
  UpdateTaskAction,
} from "../../../shared/redux/services/projectService";
import addTaskIcon from "../../../assets/images/AddTaskIcon.svg";
import addUserIcon from "../../../assets/images/addUserIcon.svg";
import CustomButton from "../../common/customButton/customButton";
import toDoIcon from "../../../assets/images/todoIcon.svg";
import inProgressIcon from "../../../assets/images/inProgressIcon.svg";
import onFireIcon from "../../../assets/images/onFireIcon.svg";
import approvalPendingIcon from "../../../assets/images/approvalPendingIcon.svg";
import reviseIcon from "../../../assets/images/reviseIcon.svg";
import approvedIcon from "../../../assets/images/approvedIcon.svg";
import CommonButton from "../../common/button/button";
import {
  isSideBarModalOpen,
  subTaskid,
} from "../../../shared/redux/action/sideBarModalAction";
// import Modal from "../Modal/modal";
import Modal from "../../common/Modal/modal";

const TaskHeader = ({ page, fetchDataOnFirstRender }) => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState([
    { label: "All", value: "" },
    { label: "Need Review", value: "Approval Pending" },
    { label: "Approved", value: "Approved" },
  ]);
  const [show, setShow] = useState(false);
  const [taskId, setTaskId] = useState("");
  const [modalHeader, setModalHeader] = useState();
  const [modalPageName, setModalPageName] = useState();
  const [statusColorCode, setStatusColorCode] = useState();
  const [selectedValue, setSelectedValue] = useState("High");
  const [url, setUrl] = useState("");
  const [EnableModalAdd, setModelAdd] = useState(false);

  const { subTaskDetails, subTaskAddedTime } = useSelector(
    (state) => state.task
  );
  const { currentUser } = useSelector((state) => state.userManagement);
  const { projectDetails, tasklist } = useSelector((state) => state.projects);
  const [statusList, setStatusList] = useState([
    "Assigned",
    "In Progress",
    "On Fire",
    "Pending Review",
    "In Review",
    "Revise",
    "Approved",
  ]);
  const [selectedFilter, setSelectedFilter] = useState("All");

  const getBackgroundColor = useCallback((value) => {
    switch (value) {
      case "To Do":
        return { class: "toDo", icon: toDoIcon };
      case "In Progress":
        return { class: "inProgress", icon: inProgressIcon };
      case "Revise":
        return { class: "revise", icon: reviseIcon };
      case "On Fire":
        return { class: "onFire", icon: onFireIcon };
      case "Approval Pending":
        return { class: "approvalPending", icon: approvalPendingIcon };
      case "Approved":
        return { class: "approved", icon: approvedIcon };
      default:
        return { class: "white", icon: "" };
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const getBackgroundColorPriority = (val) => {
    switch (val) {
      case "High":
        return "#EAB308";
      case "Medium":
        return "#F8E7B2";
      case "Low":
        return "#FCF4DA";
      default:
        return "#EAB308"; // Default color
    }
  };

  const showModal = (title, projectId) => {
    setShow(true);
    setModalHeader(title);
    let pageName = "";
    if (title === "Upload Script") {
      setModalPageName("Script");
      pageName = "Script";
    }
    if (title === "Upload Budget") {
      setModalPageName("Budget");
      pageName = "Budget";
    }
    if (title === "Create a project") {
      setModalPageName("Project");
      pageName = "Project";
    }
    if (title === "Add Member") {
      setModalPageName("Project");
      pageName = "Project";
    }

    if (title === "Add Task") {
      setModalPageName("Project");
      pageName = "Task";
    }
    dispatch(
      isSideBarModalOpen({
        pageName: pageName,
        title: title,
        pageNumber: 1,
        projectId: projectId,
      })
    );
  };

  const isEmptyObject = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const handleSelectOpen = async (status) => {
    setStatusList([]);
    const response = await dispatch(TaskStatusList(status));
    if (response.data.data?.length > 0) {
      setStatusList(response.data.data);
    }
  };

  const handleAddTask = () => {
    showModal("Add Task", projectDetails._id);
  };

  const getBackgroundColorPriorityDrop = (val) => {
    switch (val) {
      case "High":
        return "#EAB308";
      case "Medium":
        return "#F8E7B2";
      case "Low":
        return "#FCF4DA";
      default:
        return "#EAB308"; // Default color
    }
  };
  const handleAddSubTask = () => {
    dispatch(
      isSideBarModalOpen({
        pageName: "SubTask",
        title: "Add Sub Task",
        pageNumber: 1,
        projectId: projectDetails._id,
      })
    );
    dispatch(
      subTaskid({
        taskId: subTaskDetails?._id,
        ownerId: subTaskDetails?.owner?._id,
      })
    );
  };

  useEffect(() => {
    if (subTaskAddedTime && fetchDataOnFirstRender) {
      fetchDataOnFirstRender();
    }
  }, [subTaskAddedTime]);

  const handleOnChange = async (value, url, taskId) => {
    var response;
    switch (currentUser?.persona) {
      case "Finance & Control":
        response = await dispatch(
          UpdateTaskAction({
            payload: { status: value },
            taskId: taskId,
            projectId: projectDetails._id,
            userId: currentUser._id,
          })
        );
        if (response.status == 200) {
          fetchDataOnFirstRender();
        }
        break;
      case "Scheduler":
        response = await dispatch(
          UpdateTaskAction({
            payload: { status: value },
            taskId: taskId,
            projectId: projectDetails._id,
            userId: currentUser._id,
          })
        );
        if (response.status == 200) {
          fetchDataOnFirstRender();
        }
        break;
      case "High Level Creative":
        response = await dispatch(
          UpdateTaskAction({
            payload: { status: value, reviewPath: [url] },
            taskId: taskId,
            projectId: projectDetails._id,
            userId: currentUser._id,
          })
        );
        if (response?.status == 200) {
          setShow(false);
          fetchDataOnFirstRender();
        }
        break;
      default:
        response = await dispatch(
          UpdateSubTaskAction({
            payload: { status: value, reviewPath: [url] },
            taskId: taskId,
            projectId: projectDetails._id,
            userId: currentUser._id,
          })
        );
        if (response.status == 200) {
          setShow(false);
          fetchDataOnFirstRender();
        }
    }
  };

  const handleOnChangePriority = async (value, taskId, key) => {
    var response;
    switch (currentUser?.persona) {
      case "Finance & Control":
        await dispatch(
          UpdateTaskAction({
            payload: { [key]: value },
            taskId: taskId,
            projectId: projectDetails._id,
            userId: currentUser._id,
            persona: "finance",
            page: page,
          })
        );
        break;
      case "Scheduler":
        await dispatch(
          UpdateTaskAction({
            payload: { [key]: value },
            taskId: taskId,
            projectId: projectDetails._id,
            userId: currentUser._id,
            persona: "scheduler",
            page: page,
          })
        );
        break;
      case "High Level Creative":
        await dispatch(
          UpdateTaskAction({
            payload: { [key]: value },
            taskId: taskId,
            projectId: projectDetails._id,
            userId: currentUser._id,
            persona: "highLevel",
            page: page,
          })
        );
        break;
      default:
        await dispatch(
          UpdateSubTaskAction({
            payload: { [key]: value },
            taskId: taskId,
            projectId: projectDetails._id,
            userId: currentUser._id,
            persona: "generalLevel",
            page: page,
          })
        );
    }
  };

  function capitalizeFirstLetter(str) {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  }

  const handleFilter = (item) => {
    setSelectedFilter(item.label);
    switch (currentUser?.persona) {
      case "Finance & Control":
        dispatch(
          FetchTaskListAction({
            projectId: projectDetails._id,
            userId: currentUser._id,
            filter: item.value,
          })
        );
        break;
      case "Scheduler":
        dispatch(
          FetchTaskListAction({
            projectId: projectDetails._id,
            userId: currentUser._id,
            filter: item.value,
          })
        );
        break;
      case "High Level Creative":
        dispatch(
          FetchTaskListAction({
            projectId: projectDetails._id,
            userId: currentUser._id,
            filter: item.value,
          })
        );
        break;
      default:
        dispatch(
          FetchSubTaskListAction({
            projectId: projectDetails._id,
            userId: currentUser._id,
            filter: item.value,
          })
        );
    }
  };

  const handleTextareaChange = (event) => {
    // Update the textarea content
    console.log("event", event);
    if (event.indexOf("https://reviewhub.cinemindstudios.com") > -1) {
      setModelAdd(true);
    } else {
      setModelAdd(false);
    }
    // setTextareaContent(event.target.value);
  };

  const readyForReviewBtn = (item) => {
    if (currentUser?.persona === "High Level Creative") {
      return (
        <div className="footerButton">
          <Modal
            show={show}
            // show={true}
            handleClose={() => setShow(false)}
            header={"Add File for Review"}
          >
            <div>
              <div className="textContainer headerModal formScrollSection">
                <div className="textControl">
                  <label>File Url</label>
                  <TextField
                    id="outlined-error"
                    placeholder="Enter File URL"
                    fullWidth={true}
                    size="small"
                    className={`textField`}
                    name="firstName"
                    onChange={(e) => {
                      handleTextareaChange(e.target.value);
                      setUrl(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div
                className="submitButtonAdd"
                style={{
                  display: "flex",
                  gap: "20px",
                  position: "fixed",
                  bottom: "10px",
                }}
              >
                <div className="modelButtonAlign">
                  <CustomButton
                    label={"Cancel"}
                    type={"outlineButton"}
                    onclick={() => setShow(false)}
                  />
                  <CustomButton
                    label={"Add"}
                    type={`${EnableModalAdd ? "mainButton" : "disabledButton"}`}
                    onclick={() =>
                      handleOnChange("Approval Pending", url, item?._id)
                    }
                  />
                </div>
              </div>
            </div>
          </Modal>

          <CustomButton
            label={"Ready For Review"}
            type={
              item?.status === "Approval Pending" ||
              item?.status === "Approved" ||
              item.isAllSubTaskApproved === false
                ? "disabledButton"
                : "mainButton"
            }
            onclick={() => {
              setShow(true);
              setTaskId(item?._id);
            }}
          />
        </div>
      );
    }
    if (currentUser?.persona === "General Level Creative") {
      return (
        <div className="footerButton">
          <Modal
            show={show}
            // show={true}
            handleClose={() => setShow(false)}
            header={"Add File for Review"}
          >
            <div>
              <div className="textContainer headerModal formScrollSection">
                <div className="textControl">
                  <label>File Url</label>
                  <TextField
                    id="outlined-error"
                    placeholder="Enter File URL"
                    fullWidth={true}
                    size="small"
                    className={`textField`}
                    name="firstName"
                    onChange={(e) => {
                      handleTextareaChange(e.target.value);
                      setUrl(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div
                className="submitButtonAdd"
                style={{
                  display: "flex",
                  gap: "20px",
                  position: "fixed",
                  bottom: "10px",
                }}
              >
                <div className="modelButtonAlign">
                  <CustomButton
                    label={"Cancel"}
                    type={"outlineButton"}
                    onclick={() => setShow(false)}
                  />
                  <CustomButton
                    label={"Add"}
                    type={`${EnableModalAdd ? "mainButton" : "disabledButton"}`}
                    onclick={() =>
                      handleOnChange("Approval Pending", url, item?._id)
                    }
                  />
                </div>
              </div>
            </div>
          </Modal>
          <CustomButton
            label={"Ready For Review"}
            type={
              item?.status === "Approval Pending" || item?.status === "Approved"
                ? "disabledButton"
                : "mainButton"
            }
            onclick={() => {
              console.log("hello i am click 2");
              setShow(true);
              setTaskId(item?._id);
            }}
          />
        </div>
      );
    }
  };

  const priority = (subTaskDetails) => {
    if (page === "detail") {
      return (
        <div>
          <div className="dropDownPriority">
            <Select
              value={
                subTaskDetails?.priority?.charAt(0).toUpperCase() +
                subTaskDetails?.priority?.slice(1)
              }
              onChange={(event) =>
                handleOnChangePriority(
                  event.target.value,
                  subTaskDetails._id,
                  "priority"
                )
              }
              style={{
                backgroundColor: getBackgroundColorPriorityDrop(
                  subTaskDetails?.priority?.charAt(0).toUpperCase() +
                    subTaskDetails?.priority?.slice(1)
                ),
              }}
            >
              <MenuItem value="High">High</MenuItem>
              <MenuItem value="Medium">Medium</MenuItem>
              <MenuItem value="Low">Low</MenuItem>
            </Select>
          </div>
        </div>
      );
    }
  };

  const approvalPending = (subTaskDetails) => {
    const checkAppovalOwners = subTaskDetails?.approvalStatus?.filter(
      (item) => item._id === currentUser?._id
    );
    if (currentUser.persona === "Scheduler") {
      return (
        <div className="footerButton">
          <CustomButton
            label={"Revise"}
            type={
              subTaskDetails?.status !== "Approval Pending" ||
              subTaskDetails?.createrApproved === true
                ? "disabledButton"
                : "outlineButton"
            }
            onclick={() => handleOnChange("Revise", "", subTaskDetails?._id)}
          />
          <CustomButton
            label={"Approve"}
            type={
              subTaskDetails?.status !== "Approval Pending" ||
              subTaskDetails?.createrApproved === true
                ? "disabledButton"
                : "mainButton"
            }
            onclick={() => handleOnChange("Approved", "", subTaskDetails?._id)}
          />
        </div>
      );
    } else {
      return (
        <div className="footerButton">
          <CustomButton
            label={"Revise"}
            type={
              subTaskDetails?.status !== "Approval Pending" ||
              checkAppovalOwners.length > 0
                ? "disabledButton"
                : "outlineButton"
            }
            onclick={() => handleOnChange("Revise", "", subTaskDetails?._id)}
          />
          <CustomButton
            label={"Approve"}
            type={
              subTaskDetails?.status !== "Approval Pending" ||
              checkAppovalOwners.length > 0
                ? "disabledButton"
                : "mainButton"
            }
            onclick={() => handleOnChange("Approved", "", subTaskDetails?._id)}
          />
        </div>
      );
    }
  };
  return (
    <div className="inner-top-header">
      <div
        className={`inner-top-header-row ${
          page === "detail" ? "flexEnd" : "spaceBetween"
        }`}
      >
        <div className={`leftSection ${page === "detail" ? "none" : "flex"}`}>
          {filters.map((item) => (
            <div
              className={`label ${
                item.label === selectedFilter ? "labelActive" : ""
              }`}
              onClick={() => handleFilter(item)}
            >
              {item.label}
            </div>
          ))}
        </div>
        <div className="rightSection">
          {currentUser.persona === "Scheduler" && page === "project" && (
            <>
              <CustomButton
                type="outlineButton"
                frontIcon={addTaskIcon}
                label="Add Task"
                onclick={handleAddTask}
              />
              {/* {currentUser.persona !== "Scheduler"? <CustomButton
                type="outlineButton"
                frontIcon={addUserIcon}
                label="Assign Task Owner"
              />: ""} */}
            </>
          )}
          {page === "detail" &&
          currentUser.persona === "High Level Creative" &&
          (subTaskDetails?.status === "To Do" ||
            subTaskDetails?.status === "Revise" ||
            subTaskDetails?.status === "In Progress" ||
            subTaskDetails?.status === "On Fire") ? (
            <>
              <CustomButton
                type="outlineButton"
                frontIcon={addTaskIcon}
                label="Add Sub Task"
                onclick={handleAddSubTask}
              />
            </>
          ) : null}
          {page === "detail" ? (
            <div className="task-owner-button">
              {isEmptyObject(subTaskDetails) == true ? (
                <div className="owner-img">
                  {projectDetails?.owner?.firstName[0]}
                </div>
              ) : (
                <div className="owner-img">
                  {subTaskDetails?.owner?.firstName[0]}
                </div>
              )}

              {isEmptyObject(subTaskDetails) == true ? (
                <div className="owner-content">
                  {`${projectDetails?.owner?.firstName} ${projectDetails?.owner?.lastName}`}
                  <span>{projectDetails?.owner?.title}</span>
                </div>
              ) : (
                <div className="owner-content">
                  {`${subTaskDetails?.owner?.firstName} ${subTaskDetails?.owner?.lastName}`}
                  <span>{subTaskDetails?.owner?.title}</span>
                </div>
              )}
              {page === "detail" ? (
                <div className="owner-button">
                  <button className="owner-main-button">Task Owner</button>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
          {currentUser.persona !== "Scheduler" && page === "detail" ? (
            <div
              className=""
              style={{
                backgroundColor: getBackgroundColorPriority(
                  subTaskDetails?.priority
                ),
                display: "flex",
                padding: "8px 14px",
                borderRadius: "12px",
                fontSize: "10px",
                pointerEvents: "none",
                fontWeight: "400",
              }}
            >
              {subTaskDetails?.priority}
            </div>
          ) : (
            priority(subTaskDetails)
          )}

          {page === "detail" ? (
            <>
              <div className="on-fire">
                {currentUser.persona === "Scheduler" ||
                currentUser.persona === "Finance & Control" ? (
                  <div
                    className={`todoIconAlign ${
                      getBackgroundColor(subTaskDetails?.status).class
                    }`}
                  >
                    <img
                      src={getBackgroundColor(subTaskDetails?.status).icon}
                    />
                    {subTaskDetails.status}
                  </div>
                ) : subTaskDetails.status === "Approved" ||
                  subTaskDetails?.status === "Approval Pending" ? (
                  <div
                    className={`todoIconAlign ${
                      getBackgroundColor(subTaskDetails?.status).class
                    }`}
                  >
                    <img
                      src={getBackgroundColor(subTaskDetails?.status).icon}
                    />
                    {subTaskDetails?.status}
                  </div>
                ) : (
                  <Select
                    openOnFocus={true}
                    onOpen={() => {
                      handleSelectOpen(subTaskDetails?.status);
                    }}
                    onChange={(event) =>
                      handleOnChange(event.target.value, "", subTaskDetails._id)
                    }
                    className={`taskHeaderStatus ${
                      getBackgroundColor(subTaskDetails?.status).class
                    } commonStatus`}
                    value={subTaskDetails?.status || ""}
                    disabled={false}
                  >
                    {statusList?.map((item) => (
                      <MenuItem
                        value={item}
                        className={`commonStatus ${
                          getBackgroundColor(item).class
                        }`}
                        style={{ margin: "3px 0px" }}
                      >
                        <img src={getBackgroundColor(item).icon} />
                        {item}
                      </MenuItem>
                    ))}

                    <MenuItem
                      value={subTaskDetails?.status}
                      className={`commonStatus ${
                        getBackgroundColor(subTaskDetails?.status).class
                      }`}
                      style={{ margin: "3px 0px" }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          src={getBackgroundColor(subTaskDetails?.status).icon}
                        />
                        {subTaskDetails?.status}
                      </div>
                    </MenuItem>
                  </Select>
                )}
              </div>
              {currentUser.persona === "Scheduler" ||
              currentUser.persona === "Finance & Control"
                ? approvalPending(subTaskDetails)
                : readyForReviewBtn(subTaskDetails)}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default TaskHeader;
