import { combineReducers } from "redux";
import { payloads } from "./reducer/reducer";
import { sideBarReducer } from "./reducer/sideBarReducer";
import usermanagementReducer from "./reducer/usermanagementReducer";
import { loadingReducer } from "./reducer/loadingReducer";
import ProjectReducer from "./reducer/projectReducer";
import { sideBarModalReducer } from "./reducer/sideBarModalReducer";
import { logOut } from "./constant";
import storage from "redux-persist/lib/storage";
import taskReducer from "./reducer/taskReducer";

const appReducer = combineReducers({
  payloads: payloads,
  sideBar: sideBarReducer,
  userManagement: usermanagementReducer,
  projects: ProjectReducer,
  loading: loadingReducer,
  sideBarModal: sideBarModalReducer,
  task: taskReducer,
});

const rootReducer = (state, action) => {
  if (action.type === logOut) {
    storage.removeItem("persist:root");
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
