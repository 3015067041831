import {
  getSubTaskDetails,
  getSubTaskDetailsList,
  getSubTaskDetailsNotes,
  setSubTaskAddedTime,
} from "../constant";

const initialState = {
  taskList: [],
  subTaskDetails: {},
  subTaskDetailsNotes: {},
  subTaskAddedTime: null,
};

const taskReducer = (state = initialState, action) => {
  switch (action.type) {
    case getSubTaskDetails:
      return {
        ...state,
        subTaskDetails: action.payload,
      };
    case getSubTaskDetailsNotes:
      return {
        ...state,
        subTaskDetailsNotes: action.payload,
      };
    case getSubTaskDetailsList:
      return {
        ...state,
        subTaskDetailsList: action.payload,
      };
    case setSubTaskAddedTime:
      return {
        ...state,
        subTaskAddedTime: Date.now(),
      };
    default:
      return state;
  }
};

export default taskReducer;
