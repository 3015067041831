import React, { useState, Suspense, useEffect } from "react";
import Sidebar from "./sidebar/sidebar";
import Header from "./header/header";
import { useDispatch, useSelector } from "react-redux";
import CustomAlert from "../common/Toaster/toaster";
import { serviceFailureAction } from "../../shared/redux/action/loadingAction";
import "./layout.css";

export default function Layout(props) {
  const { children } = props;
  const { collaspe, error } = useSelector((state) => ({
    collaspe: state.sideBar.collaspe,
    error: state.loading.error
  }));

  return (
    <>
      <div className={`mainWrapper ${collaspe ? "closeSideBar" : ""}`}>
        <Sidebar />
        <div className="contentWrapper">
          <Header />
          <Suspense fallback={""}>
            <div>{children}</div>
          </Suspense>
        </div>
        {error !== "" && <CustomAlert message={error} />}
      </div>
    </>
  );
}
