import fileDefault from "../../../assets/images/dragDropFile/file-blank-solid-240.png";
import fileCSS from "../../../assets/images/dragDropFile/file-css-solid-240.png";
import filePdf from "../../../assets/images/dragDropFile/file-pdf-solid-240.png";
import filePng from "../../../assets/images/dragDropFile/file-png-solid-240.png";
import mp4 from "../../../assets/images/dragDropFile/mp4.svg"

export const ImageConfig = {
  default: fileDefault,
  pdf: filePdf,
  png: filePng,
  css: fileCSS,
  mp4:mp4
};
