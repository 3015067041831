import React, { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { useDispatch } from "react-redux";
import { serviceFailureAction } from "../../../shared/redux/action/loadingAction";

const CustomAlert = ({
  severity = "error",
  message,
  autoHideDuration = 5000,
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(false);
      dispatch(serviceFailureAction(""));
    }, autoHideDuration);

    return () => {
      clearTimeout(timer);
    };
  }, [autoHideDuration]);

  return (
    <div
      style={{
        zIndex: "11111",
        position: "fixed",
        left: "50%",
        transform: "translateX(-50%)",
        bottom: "70px",
      }}
    >
      {open && (
        <div>
          <Alert severity={severity} onClose={() => setOpen(false)}>
            {message}
          </Alert>
        </div>
      )}
    </div>
  );
};

export default CustomAlert;
