import { useEffect } from "react";
import notificationIcon from "../../../assets/images/notificationIcon.svg";
import profileIcon from "../../../assets/images/profileIcon.png";
import "./header.css";
import { userPool } from "../../../cognito/userPool";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logOutAction } from "../../../shared/redux/action/action";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { onSideBarTabClick } from "../../../shared/redux/action/sidebarActions";
import LogoutIcon from "@mui/icons-material/Logout";
import axios from "axios";

export default function Header() {
  const { session } = useSelector((state) => state.payloads);
  const { currentUser } = useSelector((state) => state.userManagement);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = async () => {
    console.log("session", session);
    const cookies = document.cookie.split(";");

    // for (let i = 0; i < cookies.length; i++) {
    //   const cookie = cookies[i];
    //   const eqPos = cookie.indexOf("=");
    //   const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    //   document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    // }

    document.cookie =
      "cognito" + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=[something];";
    document.cookie =
      "XSRF-TOKEN" +
      "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=[something];";
    document.cookie =
      "access_token" +
      "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=[something];";
    document.cookie =
      "id_token" + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=[something];";
    document.cookie =
      "refresh_token" +
      "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=[something];";

    // const response2 = await axios.get(
    //   "https://azur.auth.us-east-1.amazoncognito.com/saml2/logout/",
    //   {
    //     headers: {
    //       Authorization: `Bearer ${session.session.access_token}`,
    //       "Access-Control-Allow-Origin": "*",
    //     },
    //   }
    // );
    // console.log("response2", response2);
    // const response = await axios.get(
    //   "https://login.microsoftonline.com/10db07ef-8941-40d2-a1e2-872ca31dacf6/oauth2/v2.0/logout?post_logout_redirect_uri=https%3A%2F%2Fgenaivisuals.com%2Flogout"
    // );
    window.location =
      "https://login.microsoftonline.com/10db07ef-8941-40d2-a1e2-872ca31dacf6/oauth2/v2.0/logout?post_logout_redirect_uri=https%3A%2F%2Fgenaivisuals.com%2Flogout";
    // const responses = await axios.get(
    //   "https://azur.auth.us-east-1.amazoncognito.com/logout?client_id=qsroh39n7j11hft2uglnn1q5p&logout_uri=https%3A%2F%2Fgenaivisuals.com%2F"
    // );
    // console.log("response", response, responses);
    // const user = userPool.getCurrentUser();
    // console.log("user", user);
    // if (user) {
    //   user.signOut();
    //   navigate("/");
    //   localStorage.clear();
    // }
    // navigate("/");
    // dispatch(onSideBarTabClick("Dashboard"));
    localStorage.clear();
  };

  const generateRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const initialsBackgroundColor = generateRandomColor();

  const checkTokenExpiration = (token) => {
    if (token === "") {
      return true; // Indicate that logout is needed
    }
    return false; // Indicate that no action is needed
  };

  useEffect(() => {
    if (checkTokenExpiration(session.session)) {
      logout();
    }
  }, [session]);

  return (
    <div className="headerWrapper">
      <div className="headerContainer">
        {/* <div className="headerCompanyName">Company Name</div> */}
        <div className="headerRightSection">
          <div className="headerNavItem">
            {/* <div className="notifiactionIcon">
              <img src={notificationIcon} />
            </div> */}
          </div>
          <div className="headerNavItem userProfileHeaderItem">
            <div className="userProfile">
              <div>
                {!profileIcon ? (
                  <img src={profileIcon} alt="User" />
                ) : (
                  <div
                    className="initial-name"
                    style={{ backgroundColor: initialsBackgroundColor }}
                  >
                    {currentUser?.firstName?.charAt(0)}
                  </div>
                )}
                {/* <p>{currentUser?.firstName}</p> */}
              </div>

              {/* <div className="userProfileImages">
                <img src={profileIcon} />
              </div> */}
              <div className="userProfileText">
                {currentUser?.firstName} {currentUser?.lastName}
                <span>{currentUser?.persona}</span>
              </div>
              <div>
                &nbsp;
                <LogoutIcon
                  onClick={() => {
                    logout();
                    // dispatch(logOutAction());
                    // dispatch(onSideBarTabClick("Dashboard"));
                  }}
                />
              </div>

              {/* <div className="dropDownContainer">
                <ul>
                  <li
                    onClick={() => {
                      dispatch(logOutAction());
                      dispatch(onSideBarTabClick("Dashboard"));
                    }}
                  >
                    Logout
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
