import "./button.css";

const CommonButton = ({ icon, label, onClick, enableNote,backgroundColor }) => {
  return (
    <button
      className={backgroundColor === "primary"? "primaryColour": "button"}
      onClick={onClick}
      disabled={label === "Show Notes" ? !enableNote : false}
    >
      <img src={icon} />
      {label}
    </button>
  );
};

export default CommonButton;
