import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import {
  onSideBarTabClick,
  sideBarCloseAction,
  sideBarOpenAction,
} from "../../../shared/redux/action/sidebarActions";
import { FetchPojectsDetails } from "../../../shared/redux/services/projectService";
import ModalContent from "../../dashboard/modalContent";
import { isSideBarModalOpen } from "../../../shared/redux/action/sideBarModalAction";
import sidebarLogo from "../../../assets/images/sidebarLogo.svg";
import dashboardLogoIcon from "../../../assets/images/dashboardLogoIcon.svg";
import sideBarOpen from "../../../assets/images/sideBarOpen.svg";
import "./sidebar.css";

export default function Sidebar() {
  const dispatch = useDispatch();
  const { collaspe, activeTab } = useSelector((state) => state.sideBar);
  const { isSideBarOpen } = useSelector((state) => state.sideBarModal);
  const [isDivVisible, setDivVisible] = useState(false);
  const { currentUser } = useSelector((state) => state.userManagement);

  const toggleDiv = () => {
    setDivVisible(!isDivVisible);
  };

  const { projectList, projectDetails } = useSelector(
    (state) => state.projects
  );
  const [modalHeader, setModalHeader] = useState("Create a project");

  const handleSideBarCollaspe = () => {
    if (collaspe) {
      dispatch(sideBarCloseAction());
    } else {
      dispatch(sideBarOpenAction());
    }
  };

  const showImportUsers = () => {
    setDivVisible(false);
    dispatch(
      isSideBarModalOpen({
        pageName: "Project",
        title: "Create a Project",
        pageNumber: 1,
      })
    );
  };

  const handleProjectChange = (item, persona) => {
    setDivVisible(false);
    dispatch(FetchPojectsDetails({ data: item }));
  };

  const handleTabChange = (tab) => {
    dispatch(onSideBarTabClick(tab));
  };

  return (
    <div className="sideBarWrapper">
      {isSideBarOpen && (
        <ModalContent modalHeader={modalHeader} pageName={"Project"} />
      )}
      <div className="sideBarContainer">
        <button className="toggleIcon" onClick={handleSideBarCollaspe}>
          {collaspe ? (
            <img src={sideBarOpen} alt="logo" loading="lazy" />
          ) : (
            <img src={sideBarOpen} alt="logo" loading="lazy" />
          )}
        </button>
        <div className="sideBarLogo">
          {collaspe ? (
            <img src={dashboardLogoIcon} alt="logo" loading="lazy" />
          ) : (
            <img src={sidebarLogo} alt="logo" loading="lazy" />
          )}
        </div>
        <div className="sideBarNav">
          <div className="sideBaritem">
            <Link to="/dashboard">
              <div
                className={`sideBaritemWrapper ${
                  activeTab === "Dashboard" ? "active" : ""
                }`}
                onClick={() => handleTabChange("Dashboard")}
              >
                <span className="navItemIcon">
                  <DashboardOutlinedIcon fontSize="large" />
                </span>
                Dashboards
              </div>
            </Link>
          </div>

          <div className="sideBaritem">
            <div
              className={`sideBaritemWrapper ${
                activeTab === "Project" ? "active" : ""
              }`}
              onClick={toggleDiv}
            >
              <span className="navItemIcon">
                <FolderOpenOutlinedIcon fontSize="large" />
              </span>
              <div
                onClick={() => {
                  handleTabChange("Project");
                  handleProjectChange(projectList[0]);
                }}
              >
                Projects
              </div>
              {currentUser?.persona === "Scheduler" ||
              currentUser?.persona === "Finance & Control" ? (
                <span className="addIcon" onClick={() => showImportUsers()}>
                  <AddCircleRoundedIcon />
                </span>
              ) : null}
            </div>
            <div className="dropdown-content">
              <ul>
                {projectList.map((item) => (
                  <Link to="/projects" key={item._id}>
                    <li
                      className={`${
                        item._id == projectDetails._id &&
                        activeTab === "Project"
                          ? "Selected"
                          : ""
                      } `}
                      onClick={() => {
                        handleProjectChange(item);
                        handleTabChange("Project");
                      }}
                    >
                      {item.title}
                    </li>
                  </Link>
                ))}
              </ul>
            </div>
          </div>
          {isDivVisible && (
            <div className="your-div-styles">
              {collaspe ? (
                <div className="mouseHoverContentProjet">
                  {currentUser?.persona === "Scheduler" ||
                  currentUser?.persona === "Finance & Control" ? (
                    <Button
                      className="createProMouseHover"
                      onClick={() => showImportUsers()}
                    >
                      Create Project
                      <span className="addIcon">
                        <AddCircleRoundedIcon />
                      </span>
                    </Button>
                  ) : null}
                  <div className="mousehoverProjectList">
                    <ul>
                      {projectList.map((item) => (
                        <Link to="/projects">
                          <li
                            className={`${
                              item._id == projectDetails._id &&
                              activeTab === "Project"
                                ? "Selected"
                                : ""
                            } `}
                            onClick={() => {
                              handleProjectChange(item);
                              handleTabChange("Project");
                            }}
                          >
                            {item.title}
                          </li>
                        </Link>
                      ))}
                    </ul>
                  </div>
                </div>
              ) : null}
            </div>
          )}
          <div
            className="sideBaritem setuserManagement"
            style={
              currentUser?.persona === "Finance & Control" ||
              currentUser?.persona === "Scheduler"
                ? { display: "flex" }
                : { display: "none" }
            }
          >
            <Link to="/usermanagement">
              <div
                className={`sideBaritemWrapper ${
                  activeTab === "UserManagemt" ? "active" : ""
                }`}
                onClick={() => handleTabChange("UserManagemt")}
              >
                <span className="navItemIcon">
                  <GroupsOutlinedIcon fontSize="large" />
                </span>
                User Management
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
