import {
  FetchBudgetListStartAction,
  FetchProjectDetails,
  FetchProjectList,
  FetchScheduleListStartAction,
  FetchScriptListStartAction,
  FetchedBudgetList,
  FetchedScheduleList,
  FetchedScriptList,
  FetchedTaskList,
  ProjectDetailsFetchingStartAction,
  ProjectListFetchingStartAction,
} from "../action/projectActions";
import { isSideBarModalClose } from "../action/sideBarModalAction";
import { subTaskAdded } from "../action/task";
import axiosInstance from "./interseptor";
import { fetchSubTaskDetails } from "./task";
import { fetchUser } from "./userService";

export const FetchPojectsList = (payload) => async (dispatch) => {
  try {
    dispatch(ProjectListFetchingStartAction());
    const response = await axiosInstance.get(
      `/user/${payload.id}/dashboard/${payload.persona}`
    );
    // const response = await axiosInstance.get(`/user/65a8c4b8436fd3db98fc239c/dashboard/${payload.persona}`);
    dispatch(FetchProjectList(response.data.data));
    return response;
  } catch (e) {
    console.log("error in fetch list", e);
  }
};

export const FetchPojectsDetails = (payload) => async (dispatch) => {
  try {
    dispatch(ProjectDetailsFetchingStartAction());
    const response = await axiosInstance.get(`/project/${payload.data._id}`);
    dispatch(FetchProjectDetails(response.data.data));
  } catch (e) {}
};

export const addProject = (payload) => async (dispatch) => {
  try {
    const response = await axiosInstance.post(`/project`, payload.payload);
    dispatch(
      FetchPojectsList({ id: payload.userId, persona: payload.persona })
    );
    dispatch(isSideBarModalClose());
  } catch (e) {}
};

export const addProjectMember = (payload) => async (dispatch) => {
  try {
    const response = await axiosInstance.put(
      `/project/${payload.id}`,
      payload.payload
    );
    dispatch(
      FetchPojectsList({ id: payload.userId, persona: payload.persona })
    );
    dispatch(FetchPojectsDetails({ data: { _id: payload.id } }));
    dispatch(isSideBarModalClose());
    dispatch(fetchUser());
  } catch (e) {}
};

export const FetchScriptListAction = (payload) => async (dispatch) => {
  try {
    dispatch(FetchScriptListStartAction());
    const response = await axiosInstance.get(`project/${payload._id}/script`);
    dispatch(FetchedScriptList(response.data.data));
  } catch (e) {}
};

export const FetchDownloadUrlAction = (payload) => async (dispatch) => {
  try {
    const response = await axiosInstance.get(
      `downloadUrl?s3ObjectKey=${payload}`
    );
    return response.data.data;
  } catch (e) {}
};

export const FetchTaskListAction =
  ({ projectId, userId, filter = "" }) =>
  async (dispatch) => {
    try {
      let response;
      if (filter == "") {
        response = await axiosInstance.get(
          `project/${projectId}/task?userId=${userId}`
        );
      } else {
        response = await axiosInstance.get(
          `project/${projectId}/task?userId=${userId}&status=${filter}`
        );
      }

      dispatch(FetchedTaskList(response.data.data));
    } catch (e) {
      console.log("error", e);
    }
  };

export const FetchSubTaskListAction =
  ({ projectId, userId, filter = "" }) =>
  async (dispatch) => {
    try {
      let response;
      if (filter == "") {
        response = await axiosInstance.get(
          `project/${projectId}/subTask?userId=${userId}`
        );
      } else {
        response = await axiosInstance.get(
          `project/${projectId}/subTask?userId=${userId}&status=${filter}`
        );
      }
      dispatch(FetchedTaskList(response.data.data));
    } catch (e) {
      console.log("issuue-->", e);
    }
  };

export const UpdateTaskAction = (payload) => async (dispatch) => {
  console.log("pla",payload)

  try {
    const response = await axiosInstance.put(
      `task/${payload.taskId}`,
      payload.payload
    );
    if (response.data.message === "Success") {
      if (payload.page === "project") {
        dispatch(
          FetchTaskListAction({
            projectId: payload.projectId,
            userId: payload.userId,
          })
        );
      } else if (payload.page === "dashboard") {
        dispatch(
          FetchPojectsList({ id: payload.userId, persona: payload.persona })
        );
      } else if (payload.page === "detail") {
        dispatch(
          fetchSubTaskDetails({
            persona: payload.persona,
            subTaskId: payload.taskId,
          })
        );
      } else {
        dispatch(
          FetchTaskListAction({
            projectId: payload.projectId,
            userId: payload.userId,
          })
        );
      }

      return response;
    }
  } catch (e) {}
};

export const UpdateSubTaskAction = (payload) => async (dispatch) => {
  try {
    const response = await axiosInstance.put(
      `subTask/${payload.taskId}`,
      payload.payload
    );
    FetchPojectsList({ id: payload.userId, persona: payload.persona });
    if (payload.page === "project") {
      dispatch(
        FetchSubTaskListAction({
          projectId: payload.projectId,
          userId: payload.userId,
        })
      );
    } else if (payload.page === "dashboard") {
      dispatch(
        FetchPojectsList({ id: payload.userId, persona: payload.persona })
      );
    } else if (payload.page === "detail") {
      dispatch(
        fetchSubTaskDetails({
          persona: payload.persona,
          subTaskId: payload.taskId,
        })
      );
    } else {
      dispatch(
        FetchSubTaskListAction({
          projectId: payload.projectId,
          userId: payload.userId,
        })
      );
    }
    return response;
  } catch (e) {}
};

export const FetchBudgetListAction = (payload) => async (dispatch) => {
  try {
    dispatch(FetchBudgetListStartAction());
    const response = await axiosInstance.get(`project/${payload._id}/budget`);
    dispatch(FetchedBudgetList(response.data.data));
    return response;
  } catch (e) {}
};

export const FetchScheduleListAction = (payload) => async (dispatch) => {
  try {
    dispatch(FetchScheduleListStartAction());
    const response = await axiosInstance.get(`project/${payload._id}/schedule`);
    dispatch(FetchedScheduleList(response.data.data));
  } catch (e) {}
};

export const AddTaskAction = (payload) => async (dispatch) => {
  try {
    const response = await axiosInstance.post(
      `project/${payload.id}/task`,
      payload.payload
    );

    return response;
  } catch (e) {
    console.log("error", e);
  }
};

export const AddSubTaskAction = (payload) => async (dispatch) => {
  try {
    const response = await axiosInstance.post(
      `task/${payload.id}/subTask`,
      payload.payload
    );
    dispatch(subTaskAdded());
    return response;
  } catch (e) {
    console.log("error", e);
  }
};

export const TaskStatusList = (payload) => async (dispatch) => {
  try {
    const response = await axiosInstance.get(`task/state?status=${payload}`);

    return response;
  } catch (e) {
    console.log("error", e);
  }
};
