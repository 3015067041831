import {
  fetchProjectList,
  fetchProjectDetails,
  logOut,
  ProjectListFetchingStart,
  ProjectDetailsFetchingStart,
  FetchScriptListStart,
  FetchedScriptListSucess,
  FetchBudgetListStart,
  FetchedBudgetListSucess,
  getScriptNoteList,
  FetchedTaskListSucess,
  FetchedScheduleListSucess,
  FetchScheduleListStart,
  getSubTaskNoteList
} from "../constant";

const initialState = {
  loadingProjectList: false,
  loadingProjectDetails: false,
  scriptListLoading: false,
  budgetListLoading: false,
  projectList: [],
  projectDetails: [],
  scriptList: [],
  budgetList: [],
  noteList: [],
  tasklist: [],
  subTasknoteList: []
};

const ProjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case ProjectListFetchingStart:
      return { ...state, loadingProjectList: true };
    case fetchProjectList:
      return {
        ...state,
        projectList: action.payload,
        loadingProjectList: false,
      };
    case ProjectDetailsFetchingStart:
      return { ...state, loadingProjectDetails: true };
    case FetchScriptListStart:
      return { ...state, scriptListLoading: true };
    case FetchedScriptListSucess:
      return { ...state, scriptListLoading: false, scriptList: action.payload };
    case FetchedTaskListSucess:
      return { ...state, tasklist: action.payload };
    case FetchBudgetListStart:
      return { ...state, budgetListLoading: true };
    case FetchedBudgetListSucess:
      return {
        ...state,
        budgetListLoading: false,
        budgetList: action.payload,
      };
    case FetchScheduleListStart:
      return { ...state, scheduleListLoading: true };
    case FetchedScheduleListSucess:
      return {
        ...state,
        scheduleListLoading: false,
        scheduleList: action.payload,
      };
    case fetchProjectDetails:
      return {
        ...state,
        projectDetails: action.payload,
        loadingProjectDetails: false,
      };
    case getScriptNoteList:
      return {
        ...state,
        noteList: action.payload,
      };
    case getSubTaskNoteList:
      return {
        ...state,
        subTasknoteList: action.payload,
      };
    default:
      return state;
  }
};

export const projectsSelector = (state) => state.projects;

export const getProjects = (state) => {
  const someSlice = projectsSelector(state);
  return someSlice.projectDetails;
};

export const getLoadingProjects = (state) => {
  const someSlice = projectsSelector(state);
  return someSlice.loadingProjectDetails;
};

export const getScriptsList = (state) => {
  const someSlice = projectsSelector(state);
  return someSlice.scriptList?.scripts;
};

export default ProjectReducer;
