import axios from "axios";
import store from "../store";
import { logOutAction } from "../action/action";
import {
  serviceFailureAction,
  serviceRunningStartAction,
  serviceSuccessAction,
} from "../action/loadingAction";

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL:
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_BASIC_URL_PROD
      : process.env.REACT_APP_BASIC_URL_DEV,
  // Replace with your API base URL
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken) {
      config.headers["Authorization"] = accessToken;
    }

    config.headers["Content-Type"] = "application/json";

    store.dispatch(serviceRunningStartAction());
    return config;
  },
  (error) => {
    store.dispatch(serviceFailureAction(error.response.data.message));
    // Do something with request error
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    store.dispatch(serviceSuccessAction(response.data));
    return response;
  },
  (error) => {
    store.dispatch(serviceFailureAction(error?.response?.data?.message));
    if (error.response && error.response.status === 401) {
      store.dispatch(logOutAction());
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
