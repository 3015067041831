import {
  fetchedRoles,
  fetchedUsers,
  fetchedCurrentUserDetails,
  UsersFetchingStart,
} from "../constant";

export const UsersFetchingStartAction = () => {
  return {
    type: UsersFetchingStart,
  };
}; 
export const fetchedUser = (data) => {
  return {
    type: fetchedUsers,
    payload: data,
  };
};

export const fetchedRole = (data) => {
  return {
    type: fetchedRoles,
    payload: data,
  };
};

export const fetchedCurrentUserAction = (data) => {
  return {
    type: fetchedCurrentUserDetails,
    payload: data,
  };
};
