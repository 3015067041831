import { getScriptNoteLists, getSubTaskNoteLists } from "../action/projectActions";
import axiosInstance from "./interseptor";

export const getScriptNoteList = (payload) => async (dispatch) => {
  try {
    const response = await axiosInstance.get(
      `/${payload.tabName}/${payload.scriptId}/note`
    );
    if(payload.tabName === 'subTask') {
      dispatch(getSubTaskNoteLists(response.data.data));
    } else {
      dispatch(getScriptNoteLists(response.data.data));
    }
  } catch (e) {
    console.log("error", e);
  }
};

export const getScriptNoteListByUser = (payload) => async (dispatch) => {
  try {
    const response = await axiosInstance.get(
      `/${payload.tabName}/${payload.scriptId}/note/${payload.userId}`
    );
    if(payload.tabName === 'subTask') {
      dispatch(getSubTaskNoteLists(response.data.data));      
    } else {
      dispatch(getScriptNoteLists(response.data.data));
    }
  } catch (e) {
    console.log("error", e);
  }
};

export const addScriptNote = (payload) => async (dispatch) => {
  try {
    const response = await axiosInstance.post(
      `/${payload.tabName}/${payload.scriptId}/note`,
      payload.userData
    );
    dispatch(
      getScriptNoteList({
        tabName: payload.tabName,
        scriptId: payload.scriptId,
      })
    );
  } catch (e) {
    console.log("error", e);
  }
};

export const getSubTaskNotes = async (payload) => {
  try {
    const response = await axiosInstance.get(
      `/subTask/${payload.subTaskId}/note`
    );
    return response;
  } catch (e) {
    console.log("error", e);
  }
};
