import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import NotesPage from "../../common/notes/notes";
import { useSelector } from "react-redux";
import { Document, Page, pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

const drawerWidth = 480;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const AttachmentDetail = ({ handleClosePopup, fileType }) => {
  pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
  const [numOfPages, setNumberOfPages] = useState(null);
  const [open, setOpen] = useState(true);
  const { subTaskDetails } = useSelector((state) => state.task);
  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumberOfPages(numPages);
  };

  useEffect(() => {
    document.getElementById("xconnect-body").style = "overflow: hidden;";
    return () => {
      document.getElementById("xconnect-body").style = "overflow: unset;";
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100vh",
        color: "white",
        zIndex: 9,
      }}
    >
      <Box sx={{ display: "flex", height: "100%" }}>
        <Box
          component="main"
          sx={{
            width: "100%",
            flexGrow: 1,
            p: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ position: "relative", width: "80%" }}>
            <span className="close-button" onClick={handleClosePopup}>
              x
            </span>
            {fileType?.type === "video" ? (
              <video width="100%" height="400px" controls>
                <source src={fileType?.file} type="video/mp4" />
              </video>
            ) : (
              <div
                className="PreviewPDf"
                style={{ height: "500px", overflowY: "auto" }}
              >
                <Document
                  file={fileType?.file}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  {[...Array(numOfPages).keys()].map((_, index) => (
                    <Page
                      key={`page_${index + 1}`}
                      pageNumber={index + 1}
                      renderTextLayer={false}
                      scale={1.25}
                    />
                  ))}
                </Document>
              </div>
            )}
          </div>
        </Box>
        <div style={{ display: "flex" }}>
          <div onClick={handleDrawerToggle} style={{ zIndex: 9999 }}>
            {open ? (
              <ChevronRightIcon classes={{ root: "draw-arrow" }} />
            ) : (
              <ChevronLeftIcon classes={{ root: "draw-arrow" }} />
            )}
          </div>
          <Drawer
            anchor="right"
            variant="permanent"
            open={open}
            classes={{ paper: `relative ${!open && "d-none"}` }}
          >
            <NotesPage
              selectedId={subTaskDetails}
              tabName="subTask"
            ></NotesPage>
          </Drawer>
        </div>
      </Box>
    </div>
  );
};

export default AttachmentDetail;
