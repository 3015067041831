import Drawer from "@mui/material/Drawer";
import crossIcon from "../../../assets/images/crossIcon.svg";
import "./modal.css";

const Modal = ({ show, handleClose, header, children }) => {
  return (
    <>
      <Drawer
        PaperProps={{
          sx: {
            width: "25%",
          },
        }}
        anchor={"right"}
        open={show}
        onClose={handleClose}
      >
        <div className="modalHeaderWrapper">
          <div className="modalheader">{header}</div>
          <div className="cross" onClick={handleClose}>
            <img src={crossIcon} />
          </div>
        </div>
        <div>{children}</div>
      </Drawer>
    </>
  );
};

export default Modal;
