// import {createStore} from 'redux'
import persistedReducer from "./persistConfig";
import { configureStore, applyMiddleware } from "@reduxjs/toolkit";
import { thunk } from "redux-thunk";
import { persistStore } from "redux-persist";

// const store = createStore(root_reducer);
const store = configureStore(
  { reducer: persistedReducer },
  applyMiddleware(thunk)
);

export const persistor = persistStore(store);

export default store;
